const tableCols = [
  {
    text: '签约内容平台',
    sortable: false,
    value: 'signPlatform',
    width: 200,
    active: 'channel',
    slots: 'signPlatform',
  },
  {
    text: '签约内容频道',
    sortable: false,
    value: 'signChannelName',
    active: 'channel',
  },
  {
    text: '签约内容作品名',
    sortable: false,
    value: 'signChannelName',
    active: 'others',
  },
  {
    text: '签约内容链接',
    sortable: false,
    value: 'signUrl',
    slots: 'signUrl',
  },
  {
    text: '注册平台',
    sortable: false,
    value: 'registerPlatform',
    width: 120,
    slots: 'platform',
  },
  {
    text: '注册频道/主页ID',
    sortable: false,
    value: 'registerChannelId',
    width: 200,
  },
  {
    text: '注册频道/主页',
    sortable: false,
    value: 'registerChannelName',
    width: 200,
  },
  {
    text: '标签/播放量列表',
    sortable: false,
    value: 'playlist',
    slots: 'playlist',
  },
  {
    text: '创建人',
    sortable: false,
    value: 'createdUserName',
    width: 120,
  },
  {
    text: '创建时间',
    sortable: false,
    value: 'createdAt',
  },
  {
    text: '操作',
    sortable: false,
    value: 'opts',
    slots: 'opts',
    width: 100,
    fixed: 'right',
  },
]

export default tableCols
