import { typeOptions } from '@core/utils/options'
import { isObject } from '@core/utils/index'

export const resolveTypeVariant = type => {
  if (type === 'urgent') return { text: '紧急', color: 'error' }
  if (type === 'store_goods') return { text: '囤货', color: 'warning' }

  return { text: '普通', color: 'primary' }
}

export const resolveStatusVariant = status => {
  if (status === 1) return { text: '进行中', color: 'warning' }
  if (status === 2) return { text: '已完成', color: 'success' }

  // if (status === 3) return { text: '超时未完成', color: 'error' }
  // if (status === 4) return { text: '超时已完成', color: 'error' }
  // if (status === 5) return { text: '已删除', color: 'secondary' }

  return { text: '未开始', color: 'default' }
}

export const resolveTypeText = type => typeOptions.find(item => item.value === type)?.label || ''
export const resolvePlatformText = type => {
  if (type === 'xigua') return '西瓜'
  if (type === 'bilibili') return 'B站'
  if (type === 'douyin') return '抖音'
  if (type === 'kuaishou') return '快手'
  if (type === 'wangyi') return '网易'
  if (type === 'haokan') return '好看'
  if (type === 'youtube') return 'Youtube'

  return '空'
}
export const typeOperationText = operation => {
  if (operation === 'slice') return '影视'
  if (operation === 'original_own') return '原创自制'
  if (operation === 'acting') return '代运营'
  if (operation === 'original_acting') return '原创代运营'
  if (operation === 'self') return '自运营'

  return '空'
}

export const resolveOperationText = operation => {
  if (operation === 'hz_fb') return '杭州FB运营组'
  if (operation === 'jc_fb') return '晋城FB运营中心'
  if (operation === 'jc_fb_1') return '晋城FB运营1组'
  if (operation === 'jc_fb_2') return '晋城FB运营2组'
  if (operation === 'jc_fb_3') return '晋城FB运营3组'
  if (operation === 'hz_slice') return '杭州影视'
  if (operation === 'jc_slice') return '晋城影视'
  if (operation === 'hz_mcn') return '杭州代运营'
  if (operation === 'jc_mcn') return 'YT事业部'
  if (operation === 'hz_original') return '杭州原创'
  if (operation === 'jc_original') return '晋城原创'
  if (operation === 'hand_over_company') return '上交公司'
  if (operation === 'zz_incubate') return '郑州FB孵化组'
  if (operation === 'hz_incubate') return '杭州FB孵化组'
  if (operation === 'py_incubate') return '濮阳FB孵化组'
  if (operation === 'py_fb') return '濮阳FB运营组'
  if (operation === 'jc_incubate') return '晋城FB孵化组'

  return '空'
}

export const resolveIsDeleteVariant = isDelete => {
  if (isDelete === 1) return { text: '已删除', color: 'error' }

  return { text: '未删除', color: 'success' }
}

export const resolveStyleVariant = style => {
  if (style === 'textField') return '单行文本'
  if (style === 'textArea') return '多行文本'
  if (style === 'select') return '文本选择'
  if (style === 'richText') return '富文本编辑'
  if (style === 'fileInput') return '文件上传'
  if (style === 'radio') return '单项选择'
  if (style === 'checkbox') return '多项选择'

  return '未知'
}

export const resolveFormComponent = item => {
  if (item.style === 'textField') return 'text-field-base'
  if (item.style === 'select') return 'select-base'
  if (item.style === 'textArea') return 'text-area-base'
  if (item.style === 'richText') return 'rich-text-ckeditor'
  if (item.style === 'fileInput') return 'file-input-base'
  if (item.style === 'radio') return 'radio-base'
  if (item.style === 'checkbox') return 'checkbox-base'

  return 'text-label-base'
}

export const resolveTime = time => {
  if (time) return time.replace('T', ' ')

  return '-'
}

export const resolveStatusText = uploadStatus => {
  if (uploadStatus === 0) return { text: '未上传', color: 'secondary' }
  if (uploadStatus === 1) return { text: '已保存', color: 'success' }
  if (uploadStatus === 4) return { text: '上传中', color: 'warning' }
  if (uploadStatus === 5) return { text: '上传完成', color: 'success' }
  if (uploadStatus === 6) return { text: '上传失败', color: 'error' }
  if (uploadStatus === 7) return { text: '下载排队', color: 'primary' }

  return '空'
}

export const resolveTerminateState = terminateState => {
  if (terminateState === 0) return { text: '未终止', color: 'primary' }
  if (terminateState === 1) return { text: '终止中', color: 'warning' }
  if (terminateState === 2) return { text: '终止成功', color: 'success' }
  if (terminateState === 3) return { text: '终止失败', color: 'error' }

  return { text: '无', color: 'secondary' }
}

export const resolveSourceText = source => {
  if (source === 1) return { text: '创作者单视频' }
  if (source === 2) return { text: '运营本地' }
  if (source === 3) return { text: '奇妙工坊' }
  if (source === 4) return { text: '创作者批量文件' }

  return ''
}

export const resolveTranslateStatus = status => {
  if (status === 0) return { text: '已保存', color: 'primary' }
  if (status === 1) return { text: '翻译中', color: 'warning' }
  if (status === 2) return { text: '翻译完成', color: 'success' }
  if (status === 3) return { text: '翻译失败', color: 'error' }
  if (status === 4) return { text: '上传排队', color: 'info' }
  if (status === 5) return { text: '上传中', color: 'warning' }
  if (status === 6) return { text: '上传完成', color: 'success' }
  if (status === 7) return { text: '上传失败', color: 'error' }

  return { text: '无', color: 'secondary' }
}

export const resolveBidStatus = status => {
  if (status === 0) return { text: '正常', color: 'success' }
  if (status === 1) return { text: '删除', color: 'error' }

  return { text: '无', color: 'secondary' }
}

export const resolveSubmitTeam = {
  hz_original: '杭州原创',
}

export const resolveVideoCategory = {
  0: 'SHORTS视频',
  1: '中长视频',
  2: '混合视频',
}

export const categoryOption = status => {
  if (status === 0) return 'SHORTS'
  if (status === 1) return '中长视频'

  return '全部'
}

export const resolveDisplayData = (type, data, keyStr) => {
  const typeMap = {
    plain: data,
    computed: data.value,
    key: typeof keyStr === 'string' && keyStr.split('.').filter(v => v).reduce((acc, cur) => acc[cur], data),
  }

  return typeMap[type] || data
}

export const resolveDataWithKey = (data, keys) => keys?.split('.').filter(v => v).reduce((acc, cur) => acc[cur], data)

export const levelOptionResolve = item => ({
  0: 'SO',
  1: 'S1',
  2: 'S2',
  3: 'S3',
  4: 'S4',
  5: 'S5',
  6: 'S6',
  7: 'S7',
  8: 'S8',
  '-1': '无级别',
}[item])

export const isOrNotOptionResolve = item => (item ? '是' : '否')

export const statusOptionResolve = item => ({
  1: '搁置',
  0: '在运营',
}[item])

export const genResolverFromOptions = (options, optionsText = 'text', optionsValue = 'value') => {
  const optionsMap = {}
  options.forEach(item => {
    optionsMap[item[optionsValue]] = item[optionsText]
  })

  return val => optionsMap[val]
}

export const resolvePrivacy = item => {
  if (!isObject(item)) return item
  const {
    privacy, visibility, createdAt, timePublishedSeconds,
  } = item
  const privacyMap = {
    VIDEO_PRIVACY_UNLISTED: '不公开列出',
    VIDEO_PRIVACY_PRIVATE: '私享',
    VIDEO_PRIVACY_PUBLIC: new Date(timePublishedSeconds) < new Date(createdAt) ? '公开/首映' : '已安排时间',
  }
  const visibilityMap = {
    VIDEO_VISIBILITY_STATUS_USER_CONFIG: privacyMap[privacy],
    VIDEO_VISIBILITY_STATUS_TARGETED_FOR_KIDS: '公开',
    VIDEO_VISIBILITY_STATUS_BLOCKED_FOR_COPYRIGHT_PARTIALLY: '在部分国家/地区禁播',
    VIDEO_VISIBILITY_STATUS_REMOVED_FOR_COMMUNITY_GUIDELINES: '已移除',
  }

  return visibilityMap[visibility] || '未知'
}
