import axios from '@axios'
import url from './url'
import config from '../../../../config'

const prefix = config.serviceUrl

/**
 * 邮箱账号列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {emailAddress} 邮箱地址
 * @param {pageLink} 个人主页链接
 * @param {useUserName} 使用人
 * @param {type} 类型0.youtube,1.facebook
 */

export const getPage = params => axios.get(prefix + url.PAGE, { params })

/**
 * 新增编辑
 * @param {id} 主键
 * @param {emailAddress} 邮箱地址
 * @param {pageLink} 个人主页链接
 * @param {useUserName} 使用人
 */
export const save = params => axios.post(prefix + url.SAVE, params)

/**
 * 移除
 * @param {id} 主键
 */
export const remove = params => axios.post(prefix + url.REMOVE, params)
