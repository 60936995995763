<script>
import {
  defineComponent, computed, ref,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { channelMultipleOptions } from '@core/utils/options'
import { required } from '@core/utils/validation'
import { setChannel } from '../../api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const subsetType = ref(props.currItem.collectionTypeValue)
    const formRef = ref()
    const radioMap = ref(channelMultipleOptions)

    const { message } = useMessage()

    const handleClose = () => {
      emit('input', false)
    }

    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      if (!valid) return

      try {
        const { data: { status, message: messageText } } = await setChannel({
          id: props.currItem.id,
          collectionTypeValue: subsetType.value,
        })
        if (status === 200) {
          message.success('设置成功')
          handleClose()
          emit('success')
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    return {
      showDialog,
      radioMap,
      subsetType,
      formRef,
      required,
      handleClose,
      handleSubmit,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="500"
  >
    <v-card class="form-box">
      <h3 class="form-title">
        设置<span v-if="currItem.channelName">({{ currItem.channelName }})</span>
        <v-btn
          icon
          small
          title="关闭"
          style="position: absolute; top: 16px; right: 20px;"
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </h3>
      <v-form
        ref="formRef"
        class="current-info"
      >
        <div class="current-item">
          <label>合集/单开</label>
          <div>
            <v-radio-group
              v-model="subsetType"
              :rules="[required]"
              small
            >
              <v-radio
                v-for="item in radioMap"
                :key="item.value"
                :label="item.text"
                :value="item.value"
                :ripple="false"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="handleClose"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          @click="handleSubmit"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 20px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  label{
    display: block;
    width: 94px;
    text-align: left;
    height: 34px;
    color: rgba(0, 0, 0, 0.85);
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .current-info{
    padding: 20px 20px 0 20px;
    font-size: 14px;
    .current-item{
      display: flex;
      align-items: left;
      margin-bottom: 10px;
      ::v-deep .v-input--selection-controls{
        margin-top: -2px;
      }
      ::v-deep .v-input--radio-group__input{
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        .v-radio{
          margin-right: 20px;
        }
      }
      ::v-deep .v-input--selection-controls{
        margin-top: -2px;
      }
      ::v-deep .v-label{
        font-size: 14px;
      }
      ::v-deep .v-input--radio-group__input{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .v-radio{
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
      ::v-deep .v-input--selection-controls__input{
        width: 16px;
        height: 16px;
      }
      .radio-item{
        display: flex;
        align-items: center;
        label{
          width: 50px;
          text-align: left;
        }
      }
    }
  }
}
</style>
