import { can } from '@core/utils/useAccess'

const tableCols = [
  {
    text: '',
    type: 'checkbox',
    value: 'check',
    width: 50,
    fixed: 'left',
  },
  {
    text: '申请单编号',
    sortable: false,
    value: 'applicationNo',
    fixed: 'left',
    width: 150,
  },
  {
    text: '签约频道/作品名',
    sortable: false,
    value: 'channelName',
    slots: 'channelName',
    width: 200,
  },
  {
    text: '注册类型',
    sortable: false,
    value: 'registerType',
    width: 150,

    // formatter: ({ cellValue }) => (registerTypeOptions.find(row => row.value === cellValue) || {}).text || '-',
  },
  {
    text: '运营类型',
    sortable: false,
    value: 'operationMode',
    width: 150,

    // formatter: ({ cellValue }) => (operationModeOptions.find(row => row.value === cellValue) || {}).text || '-',
  },
  {
    text: '组别',
    sortable: false,
    value: 'operationGroupName',
    width: 150,
  },
  {
    text: '组长',
    sortable: false,
    value: 'operationLeaderName',
    width: 100,
  },
  {
    text: '组员',
    sortable: false,
    value: 'operatingMemberName',
    width: 100,
  },
  {
    text: '领用人',
    sortable: false,
    value: 'applicantName',
    width: 100,
  },
  {
    text: '创建时间',
    sortable: false,
    value: 'createdAt',
    width: 150,
  },
  {
    text: '状态',
    sortable: false,
    value: 'status',
    width: 150,
    align: 'left',
    slots: 'status',
  },
  {
    text: '注册频道/主页',
    value: 'registerChannel',
    width: 150,
    slots: 'registerChannel',
  },
  {
    text: '频道后台设置',
    sortable: false,
    value: 'backendSettingFlag',
    width: 150,
  },
  {
    text: 'Banner设计',
    sortable: false,
    value: 'bannerDesignFlag',
    width: 150,
  },
  {
    text: '操作',
    sortable: false,
    value: 'opts',
    slots: 'opts',
    minWidth: can(['超级管理员', '研发管理员', '总编室管理员']) ? 242 : 150,
    fixed: 'right',
  },
]

export default tableCols
