import axios from '@axios'
import url from './url'
import config from '../../config'

const prefix = config.serviceUrl

/**
 * 获取线索审核列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {signChannelName} 签约频道
 * @param {signWorks} 签约作品
 * @param {registerPlatformValue}  注册平台
 * @param {registerChannelName} 注册频道
 * @param {playlist} 标签/播放列表
 * @param {type} 0频道 1作品
 *
 */

export const getPage = params => axios.get(prefix + url.PAGE, { params })

/**
 * 新增编辑
 * @param {signPlatform} 签约内容平台
 * @param {signUrl} 签约内容链接
 * @param {signChannelId} 签约内容频道
 * @param {signChannelName} 签约内容频道名称
 * @param {registerChannelName} 注册频道名称
 * @param {registerPlatform} 注册平台
 * @param {registerChannelId} 注册频道
 * @param {playlist} 播放列表
 * @param {type} 0频道 1作品
 * @param {videoCustomTag} 视频自定义标签
 */
export const saveWays = params => axios.post(prefix + url.SAVE, params)

/**
 * 删除
 * @param {id} id
 * @param {type} 0频道 1作品
 */
export const removeWays = params => axios.delete(prefix + url.REMOVE, { params })

/**
 * 获取签约频道列表
 * @param {platform} 平台
 */
export const getSignChannel = params => axios.get(prefix + url.SIGN_CHANNEL, { params })

/**
 * 获取注册频道列表
 * @param {type} 平台
 */
export const getRegisterChannel = params => axios.get(prefix + url.OVERSEA_CHANNEL, { params })

/**
 * 获取签约作品列表
 * @param {signChannelId} 签约频道
 */

// 新增
export const channelCreate = params => axios.post(`${prefix}/channel/create`, params)

// 编辑
export const channelConfig = params => axios.post(`${prefix}/channel/config`, params)

// 批量设置
export const channelBatchConfig = params => axios.post(`${prefix}/channel/batchConfig`, params)

// 导入获利状态
export const channelImportProfit = params => axios.post(`${prefix}/channel/importProfit`, params.form)

export const channelExport = params => axios.post(`${prefix}/channel/export`, params, { responseType: 'blob' })

// eslint-disable-next-line comma-dangle
export const fieldList = params => axios.get(`${prefix}/youtube/field/list`, params, { responseType: 'blob', })

export const exportExls = params => axios.get(`${config.serviceUrl}/download/${params.taskId}`, {
  responseType: 'blob',
})

// 获取子集
export const getNewSubset = params => axios.get(`${prefix}/channel/getByChannelId?channelId=${params.channelId}`)
