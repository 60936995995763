export default {
  PAGE: '/content/application/queryPage',
  MARKS: '/content/application/batchConfig',
  AUDIT: '/content/application/audit',
  EXPORT: '/content/application/export',
  DETAIL: '/content/application/getById',
  REGISTER: '/content/application/register',
  GROUP: '/assets/departmentList',
  USER: '/assets/xdl/user',
}
