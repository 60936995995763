import axios from '@axios'
import url from './url'
import config from '../../../../config'

const prefix = config.serviceUrl

/**
 * 邮箱账号列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {title} 视频标题
 * @param {platform} 发布平台
 * @param {channelName} 发布频道
 * @param {videoCreatedAtLeft} 开始时间
 * @param {videoCreatedAtRight} 结束时间
 */

export const getPage = params => axios.get(prefix + url.PAGE, { params })

/**
 * 设置垂类
 * @param {id} 列表id
 * @param {categoryId} 垂类
 * @param {parentCategoryId} 上级垂类
 */
export const setCategory = params => axios.get(prefix + url.SET_CATEGORY, { params })
