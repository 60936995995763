import axios from '@axios'
import url from './url'
import config from '../../../../config'

const prefix = config.serviceUrl

/**
 * 视频异常列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {channelId} 频道ID
 * @param {channelName} 频道名
 * @param {profit} 获利
 * @param {title} 标题
 * @param {videoId} 视频ID
 * @param {restriction} 限制
 */

export const getPage = params => axios.get(prefix + url.PAGE, { params })

/**
 * 导出
 */
export const getExport = params => axios.get(prefix + url.EXPORT, { params, responseType: 'blob' })
