import axios from '@axios'
import url from './url'
import config from '../../../../config'

const prefix = config.serviceUrl

/**
 * 领用注册列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {authorizePlatform} 类型youtube,facebook
 * @param {applicationNo} 申请单编号
 * @param {contentJson} 签约频道/作品名
 * @param {registerType} 注册类型
 * @param {operatingType} 运营类型
 * @param {operationGroupId} 组别
 * @param {operatingMemberId} 组员
 * @param {applicantId} 领用人
 * @param {startDate} 起始时间
 * @param {endDate} 结束时间
 * @param {status} 状态
 */

export const getPage = params => axios.post(prefix + url.PAGE, params)

/**
 * 领用注册批量标记
 * @param {ids}
 */
export const marks = params => axios.post(prefix + url.MARKS, params)

/**
 * 审核
 */
export const audit = params => axios.post(prefix + url.AUDIT, params)

/**
 * 导出
 * @param {type}
 */
export const getExport = params => axios.post(prefix + url.EXPORT, params, { responseType: 'blob' })

/**
 * 获取详情
 */
export const getDetail = params => axios.get(prefix + url.DETAIL, { params })

/**
 * 注册
 * @param {id}
 * @param {channelId} 频道ID
 * @param {channelName} 频道名
 * @param {collectType} 单开合集
 * @param {registerTime} 注册时间
 */
export const register = params => axios.post(prefix + url.REGISTER, params)

/**
 * 获取组别
 * @param {platform} 平台
 */
export const getGroup = () => axios.get(prefix + url.GROUP + config.corpId)

/**
 * 获取用户
 * @param {platform} 平台
 */
export const getUsers = params => axios.get(prefix + url.USER, { params })
