import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { trueOrFalseOptions, restrictionOptions } from '@core/utils/options'
import { getPage } from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(0)
  const searchRef = ref()

  const { message } = useMessage()

  // 查询初始化参数
  const queryDataOri = {
    channelId: null,
    channelName: null,
    profit: null,
    title: null,
    videoId: null,
    restriction: null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const searchConfig = computed(() => [
    {
      element: 'VTextField',
      props: 'channelId',
      placeholder: '频道ID',
      label: '频道ID',
    },
    {
      element: 'VTextField',
      props: 'channelName',
      placeholder: '频道名',
      label: '频道名',
    },
    {
      element: 'VAutocomplete',
      props: 'profit',
      placeholder: '获利',
      label: '获利',
      items: trueOrFalseOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'VTextField',
      props: 'title',
      placeholder: '标题',
      label: '标题',
    },
    {
      element: 'VTextField',
      props: 'videoId',
      placeholder: '视频ID',
      label: '视频ID',
    },
    {
      element: 'VAutocomplete',
      props: 'restriction',
      placeholder: '限制',
      label: '限制',
      items: restrictionOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
  ])

  const fetchList = async () => {
    loading.value = true
    const {
      data: {
        data = [], status, total, message: messageText,
      },
    } = await getPage({
      page: page.value,
      pageSize: pageSize.value,
      ...queryData,
    })
    loading.value = false

    if (status === 200) {
      pageList.value = data
      pageLength.value = total
    } else {
      message.error(messageText)
    }
  }

  const search = async params => {
    Object.assign(queryData, params)
    page.value = 1
    fetchList()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
  }

  onMounted(() => {
    fetchList()
  })

  return {
    searchConfig,
    queryData,
    queryDataOri,
    loading,
    pageList,
    page,
    pageSize,
    pageLength,
    searchRef,

    search,
    fetchList,
    handleSizeChange,
    handleCurrentChange,
  }
}
