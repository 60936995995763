import axios from '@axios'
import url from './url'
import config from '../../../../config'

const prefix = config.serviceUrl

/**
 * 获取线索审核列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {title} 视频标题
 * @param {channelName} 发布频道
 * @param {beginTime} 开始时间
 * @param {endTime} 结束时间
 * @param {tag} 标签
 * @param {publishStatus} 发布状态
 * @param {name} 作品名
 * @param {categoryValue} 垂类
 */

export const getPage = params => {
  const { activeItem, ...others } = params
  let oUrl = url.PAGE

  if (activeItem.key !== 'channel') {
    oUrl = `${url.COMPOSITION_PAGE}/${activeItem.value}`
  }

  return axios.get(prefix + oUrl, { params: others })
}

/**
 * 获取时间线
 * @param {videoId} 视频ID
 */
export const getTimeLine = params => axios.get(prefix + url.TIME_LINE, { params })

/**
 * 设置垂类
 * @param {id} 列表id
 * @param {categoryId} 垂类
 * @param {parentCategoryId} 上级垂类
 */
export const setCategory = params => axios.get(prefix + url.SET_CATEGORY, { params })
