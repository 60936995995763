import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"form-box"},[_c('h3',{staticClass:"form-title"},[_vm._v(" 合集"),(_vm.currItem.channelName)?_c('span',[_vm._v("("+_vm._s(_vm.currItem.channelName)+")")]):_vm._e(),_c(VBtn,{staticStyle:{"position":"absolute","top":"16px","right":"20px"},attrs:{"icon":"","small":"","title":"关闭"},on:{"click":_vm.handleClose}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" mdi-close ")])],1)],1),_c('div',{staticStyle:{"padding":"10px 20px 20px 20px"}},[_c('vxe-table',{attrs:{"data":_vm.list,"border":"","max-height":"200"}},_vm._l((_vm.tableColsCurr),function(item){return _c('vxe-column',{key:item.value,attrs:{"field":item.value,"title":item.text}})}),1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticStyle:{"min-width":"65px"},attrs:{"color":"secondary","outlined":""},on:{"click":_vm.handleClose}},[_vm._v(" 关闭 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }