import { restrictionOptions, monetizationOptions, trueOrFalseOptions } from '@core/utils/options'

const tableCols = [
  {
    text: '频道ID',
    sortable: false,
    value: 'channelId',
    fixed: 'left',
  },
  {
    text: '频道名',
    sortable: false,
    value: 'channelName',
  },
  {
    text: '获利',
    sortable: false,
    value: 'profit',
    width: 100,
    formatter: ({ cellValue }) => trueOrFalseOptions.find(item => item.value === cellValue)?.text || '-',
  },
  {
    text: '视频',
    sortable: false,
    value: 'video',
    slots: 'video',
    width: 400,
  },
  {
    text: '公开范围',
    sortable: false,
    value: 'privacy',
    formatter: () => '已移除',
  },
  {
    text: '创收状态',
    sortable: false,
    value: 'monetization',
    formatter: ({ cellValue }) => monetizationOptions.find(item => item.value === cellValue)?.text || '-',
  },
  {
    text: '限制',
    sortable: false,
    value: 'restriction',
    width: 100,
    formatter: ({ cellValue }) => restrictionOptions.find(item => item.value === cellValue)?.text || '-',
  },
  {
    text: '日期',
    sortable: false,
    value: 'dateTime',
    slots: 'dateTime',
    width: 250,
  },
]

export default tableCols
