<script>
import {
  defineComponent, ref, computed, reactive,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import tableCols from './tableCols'
import useSearch from './useSearch'
import { remove } from './api'
import Add from './components/Add.vue'

export default defineComponent({
  components: {
    Add,
  },
  setup() {
    const showEdit = ref(false)
    const currItem = ref({})

    const tabsConfig = reactive([
      {
        name: 'YouTube',
        key: 'youtube',
      },
      {
        name: 'Facebook',
        key: 'facebook',
      },
    ])

    const { message } = useMessage()

    const {
      queryDataOri, fetchList, searchConfig, queryData, active, ...searchOthers
    } = useSearch()

    // 分tab处理table和search配置
    const tableColsFilter = computed(() => tableCols.filter(x => !x.active || x.active === active.value))
    const searchConfigFilter = computed(() => searchConfig.value.filter(x => !x.active || x.active === active.value))

    const handleAdd = () => {
      showEdit.value = true
      currItem.value = {}
    }

    const handleExport = () => {

    }

    const handleEdit = row => {
      showEdit.value = true
      currItem.value = row
    }

    const handleRemove = async row => {
      const { data: { status, message: messageText } } = await remove({ id: row.id })
      if (status === 200) {
        message.success('删除成功')
        fetchList()
      } else {
        message.error(messageText)
      }
    }

    return {
      active,
      tabsConfig,
      tableCols: tableColsFilter,
      searchConfig: searchConfigFilter,
      ...searchOthers,
      showEdit,
      currItem,
      handleAdd,
      handleEdit,
      handleExport,
      handleRemove,
    }
  },
})
</script>

<template>
  <v-card elevation="0">
    <XwTabs
      :tabs="tabsConfig"
      :active="active"
      @change="handleTabChange"
    ></XwTabs>
    <div style="padding: 12px 20px">
      <!--搜索区域-->
      <XwSearch
        ref="searchRef"
        :form-config="searchConfig"
        @search="search"
      />
    </div>
    <!--按钮区域-->
    <div style="padding: 0 20px 20px 20px">
      <v-btn
        color="primary"
        depressed
        @click="handleAdd"
      >
        新增
      </v-btn>
      <v-btn
        class="ml-2"
        color="primary"
        outlined
        @click="handleExport"
      >
        导出
      </v-btn>
    </div>
    <!-- table -->
    <div style="padding: 0 20px">
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <XwTable
        ref="tableRef"
        :columns="tableCols"
        :rows="pageList"
      >
        <template #opts="{row}">
          <a
            @click="handleEdit(row)"
          >
            编辑
          </a>
          <a
            @click="handleRemove(row)"
          >
            删除
          </a>
        </template>
      </XwTable>

      <div
        v-if="pageLength"
      >
        <new-pagination
          :page-index="page"
          :page-size="pageSize"
          :total="pageLength"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--新增、编辑-->
    <Add
      v-model="showEdit"
      :item="currItem"
      @sucess="handleRefresh"
    />
  </v-card>
</template>
