import axios from '@axios'
import url from './url'
import config from '../../../../config'

const prefix = config.serviceUrl

/**
 * 获取线索审核列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {channelId} 频道ID
 * @param {channelName} 频道名称
 * @param {categoryValue} 垂类
 * @param {operationTeamValue} 运营团队
 * @param {collectionTypeValue} 集合类型
 * @param {profitValue} 是否获利
 * @param {type} 类型0.youtube,1.facebook
 */

export const getPage = params => axios.post(prefix + url.PAGE, params, { params: { page: params.page, pageSize: params.pageSize } })

/**
 * 设置单开合集
 *  @param {collectionTypeValue} 0.单开,1.合集
 *  @param {id} 列表id
 */
export const setChannel = params => axios.post(prefix + url.SETTING, params)

/**
 * 合集-子集列表
 * @param {id} 列表id
 */
export const getSubset = params => axios.post(prefix + url.SUBSET, params)

/**
 * 时间线
 * @param {id} 列表id
 */
export const getTimeLine = params => {
  const { type, id } = params

  let param = {}
  if (type === 'signChannel') {
    param = { signChannelId: id }
  } else {
    param = { registerChannelId: id }
  }

  return axios.get(prefix + url.TIME_LINE, { params: param })
}
