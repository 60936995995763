<!-- eslint-disable eqeqeq -->
<!-- eslint-disable no-use-before-define -->
<script>
import {
  defineComponent, computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { required } from '@core/utils/validation'
import {
  // eslint-disable-next-line import/named
  fieldOptions, operationGroupOptionsByYtnews, operationGroupOptionsByFbnews, levelOptionsnews, channelStates,
  contentClass, homepageProperies, homepageStatus, operationModeYTOptions,
} from '@core/utils/options'
import {
  channelCreate, channelConfig,
} from '@/api/accountHomePageAdd'

import DatePickers from '@core/components/search-form/DateRangePickers.vue'
import dayjs from 'dayjs'
import VCascaderVue from '@core/components/search-form/VCascader.vue'

export default defineComponent({
  components: {
    DatePickers,
    VCascaderVue,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: 'channel',
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    showEdit: {
      type: [Boolean, String],
      default: false,
    },
    categoryList: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const formData = reactive({
      channelId: null,

      // 新增
      id: null,
      channelName: null,
      categoryId: null,
      parentCategoryId: null,
      operationTeam: null,
      collectionType: 1,
      profit: null,
      incomeScale: null,
      registerDate: '',
      profitDate: '',
      contentType: null, // facebook
      pageAttribute: null,
      pageStatus: 1, // facebook 编辑 默认正常运行
      isShelve: null, // facebook 编辑 搁置

    })
    const formRef = ref()
    const showDialog = computed(() => props.value)
    const showEdits = ref(false)
    const titleTop = ref('新增')
    const { message } = useMessage()
    const categoryObj = ref({})

    const handleClose = () => {
      emit('input', false)
    }

    const handleCategoryChange = (value, nodes = []) => {
      const [item] = nodes
      categoryObj.value = item
    }

    const handleSubmit = async () => {
      const valid = formRef.value.validate()
      let youTuIs = true
      const re = /^UC[0-9A-Za-z_-]{21}[AQgw]/
      if (!re.test(formData.id) && isChannel.value && !showEdits.value) {
        message.error('频道ID格式不正确')
        youTuIs = false
      }

      if (!valid || !youTuIs) return

      try {
        // isChannel 区分 true youTube facebook , 新增type: 0 youTube 1 facebook
        let postChannel = channelCreate
        if (showEdits.value) {
          postChannel = channelConfig
        }
        if (!showEdits.value && !isChannel.value) {
          formData.pageStatus = 1
        }

        // pageStatus
        const { data: { status, message: messageText } } = await postChannel({
          id: formData.id,
          channelName: formData.channelName,
          categoryId: formData.categoryId,
          parentCategoryId: categoryObj.value.parentId || props.currItem.parentCategoryId,
          operationTeam: formData.operationTeam,
          collectionType: formData.collectionType,
          profit: formData.profit ? 1 : 0,
          incomeScale: formData.incomeScale,
          registerDate: formData.registerDate,
          profitDate: formData.profitDate,
          contentType: formData.contentType, // facebook
          pageAttribute: formData.pageAttribute,
          pageStatus: [formData.pageStatus], // facebook 编辑
          isShelve: formData.isShelve ? 1 : 0, // facebook 编辑 搁置
          type: isChannel.value ? 0 : 1, // 0 youTube 1 facebook
          oprationMode: formData.oprationMode,
        })
        if (status === 200) {
          message.success('提交成功')
          emit('success')
          handleClose()
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    const isChannel = computed(() => props.active === 'youtube')

    const channelId = props.active === 'youtube' ? '频道ID' : '主页ID'
    const channelName = props.active === 'youtube' ? '频道名' : '主页名'

    // 跳转
    const handleLink = url => {
      if (url) {
        window.open(url)
      }
    }

    const handleRegisterPlatformChange = () => {
      formData.channelId = null
    }

    onMounted(() => {
      if (props.showEdit) {
        formData.id = props.currItem.id
        formData.channelName = props.currItem.channelName
        formData.channelId = props.currItem.channelId
        formData.categoryId = props.currItem.categoryId
        formData.operationTeam = props.currItem.operationTeamValue
        formData.collectionType = props.currItem.collectionTypeValue
        formData.profit = props.currItem.profit == '是'
        formData.incomeScale = props.currItem.incomeScale ? props.currItem.incomeScale.toString() : props.currItem.incomeScale
        formData.registerDate = props.currItem.registerDate ? dayjs(props.currItem.registerDate).format('YYYY-MM-DD') : ''
        formData.profitDate = props.currItem.profitDate ? dayjs(props.currItem.profitDate).format('YYYY-MM-DD') : ''
        formData.contentType = props.currItem.contentTypeValue
        formData.pageAttribute = props.currItem.pageAttributeValue ? props.currItem.pageAttributeValue.toString() : props.currItem.pageAttributeValue
        formData.pageStatus = props.currItem.pageStatus
        formData.isShelve = props.currItem.isShelve

        titleTop.value = '设置'
        showEdits.value = true
      }
    })

    return {
      formData,
      showDialog,
      formRef,
      required,
      isChannel,

      handleClose,
      handleLink,
      handleSubmit,
      handleRegisterPlatformChange,
      handleCategoryChange,

      // 下拉
      fieldOptions,
      operationGroupOptionsByYtnews,
      operationGroupOptionsByFbnews,
      levelOptionsnews,
      channelStates,
      contentClass,
      homepageProperies,
      homepageStatus,
      operationModeYTOptions,

      titleTop,
      channelId,
      channelName,
      showEdits,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="800"
  >
    <v-card class="form-box">
      <h3 class="form-title">
        {{ titleTop }}
      </h3>
      <v-form
        ref="formRef"
        class="form-outer"
      >
        <!--form-inner-->
        <div class="form-inner">
          <div
            class="form-item"
          >
            <label>{{ channelId }}</label>
            <div>
              <v-tooltip
                v-if="showEdits"
                right
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <span
                    v-bind="attrs"
                    class="text--link edit-des"
                    v-on="on"
                  >{{ formData.id }}</span>
                </template>
                <div>
                  <div>
                    {{ formData.id }}
                  </div>
                </div>
              </v-tooltip>

              <v-text-field
                v-if="!showEdits"
                v-model="formData.id"
                :rules="[required]"
                dense
                hide-details
                outlined
                :placeholder="channelId"
                single-line
                style="width: 250px"
              ></v-text-field>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>垂类</label>
            <div>
              <VCascaderVue
                v-model.trim="formData.categoryId"

                :items="categoryList"
                no-data-text="暂无数据"
                hide-details
                placeholder="垂类"
                dense
                outlined
                clearable
                item-text="name"
                item-value="id"
                style="width: 250px"
                @change="handleCategoryChange"
              ></VCascaderVue>
            </div>
          </div>
          <div
            v-if="isChannel"
            class="form-item"
          >
            <label>运营类型</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.pageAttribute"
                :items="operationModeYTOptions"
                no-data-text="暂无数据"
                hide-details
                placeholder="运营类型"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            v-if="!isChannel"
            class="form-item"
          >
            <label>主页属性</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.pageAttribute"
                :items="homepageProperies"
                no-data-text="暂无数据"
                hide-details
                placeholder="主页属性"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>单开/合集</label>
            <div class="singeRadio">
              <v-radio-group
                v-model="formData.collectionType"
                row
              >
                <v-radio
                  label="单开"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="合集"
                  :value="0"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>运营级别</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.incomeScale"
                :items="levelOptionsnews"
                no-data-text="暂无数据"
                hide-details
                placeholder="运营级别"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            v-if="!isChannel && showEdits"
            class="form-item"
          >
            <label>主页状态</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.pageStatus"
                :items="homepageStatus"
                no-data-text="暂无数据"
                hide-details
                placeholder="主页状态"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>注册日期</label>
            <div class="w200">
              <DatePickers
                v-model="formData.registerDate"
                :multiple="false"
                :range="false"
                clearable
              ></DatePickers>
            </div>
          </div>
        </div>
        <!--form-inner-->
        <div class="form-inner">
          <div
            class="form-item"
          >
            <label>{{ channelName }}</label>
            <div>
              <span
                v-if="showEdits"
                class="edit-des"
              >{{ formData.channelName }}</span>
              <v-text-field
                v-if="!showEdits"
                v-model="formData.channelName"
                :rules="[required]"
                dense
                hide-details
                outlined
                :placeholder="channelName"
                single-line
                style="width: 250px"
              ></v-text-field>
            </div>
          </div>

          <div
            v-if="!isChannel"
            class="form-item"
          >
            <label>内容分类</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.contentType"
                :items="contentClass"
                no-data-text="暂无数据"
                hide-details
                placeholder="内容分类"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>运营团队</label>
            <div>
              <v-autocomplete
                v-if="isChannel"
                v-model.trim="formData.operationTeam"
                :items="operationGroupOptionsByYtnews"
                no-data-text="暂无数据"
                hide-details
                placeholder="运营团队"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
              <v-autocomplete
                v-if="!isChannel"
                v-model.trim="formData.operationTeam"
                :items="operationGroupOptionsByFbnews"
                no-data-text="暂无数据"
                hide-details
                placeholder="运营团队"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>获利</label>
            <div class="switch-box">
              <div class="d-flex align-center">
                <span
                  id="switch-des"
                  class="text--secondary text-sm first-secondary"
                >开</span>
                <v-switch
                  v-model="formData.profit"
                  hide-details
                  class="mt-0 mx-2"
                  inset
                ></v-switch>
                <span
                  id="switch-des"
                  class="text--secondary text-sm second-secondary"
                >关</span>
              </div>
            </div>
          </div>

          <div
            v-if="!isChannel && showEdits"
            class="form-item"
          >
            <label>搁置</label>
            <div class="switch-box">
              <div class="d-flex align-center">
                <span
                  id="switch-des"
                  class="text--secondary text-sm first-secondary"
                >开</span>
                <v-switch
                  v-model="formData.isShelve"
                  hide-details
                  class="mt-0 mx-2"
                  inset
                ></v-switch>
                <span
                  id="switch-des"
                  class="text--secondary text-sm second-secondary"
                >关</span>
              </div>
            </div>
          </div>

          <div
            v-if="isChannel"
            class="form-item"
          >
            <label>频道状态</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.pageStatus"
                :items="channelStates"
                no-data-text="暂无数据"
                hide-details
                placeholder="频道状态"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>

          <div
            class="form-item"
          >
            <label>获利日期</label>
            <div class="w200">
              <DatePickers
                v-model.trim="formData.profitDate"
                clearable
              ></DatePickers>
            </div>
          </div>
          <!-- 新增部分结束 -->
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="handleClose"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          @click="handleSubmit"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 16px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .form-wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    flex-shrink: 0;
  }
  .form-outer{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    padding: 24px 0;
    .form-inner{
      padding-left: 20px;
    }
  }
  .form-item{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    position: relative;
    label{
    display: block;
    width: 114px;
    text-align: right;
    height: 34px;
    font-size: 14px;
    line-height: 34px;
    padding-right: 12px;
    color: rgba(0, 0, 0, 0.85);
    span{
      color: rgba(0, 0, 0, 0.25)
    }
  }
  }
  .form-arro{
    position: absolute;
    left: -8px;
    top: -2px;
    width: 20px;
    height: 14px;
    margin-top: 12px;
    margin-bottom: 36px;
    margin-left: 12px;
    display: block;
    // background: url('../../../../../assets/images/icons/arro_rt.png');
    background: url('~@/assets/images/icons/arro_rt.png');
  }
}

.w200{
  width: 260px;
}
.edit-des{
  display: inline-block;
  padding-top: 5px;
  width: 260px;
}
</style>
<style lang="scss">
.singeRadio{
  .v-input--selection-controls {
    margin-top: 3px;
    padding-top: 4px;
  }
  .v-input__control {
    height: 32px;
  }
  .v-input--selection-controls__ripple {
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    position: absolute;
    transition: inherit;
    width: 18px;
    left: -7px;
    top: calc(50% - 16px);
    margin: 7px;
  }
  .v-input--selection-controls__input {
      color: inherit;
      display: inline-flex;
      flex: 0 0 auto;
      height: 18px;
      position: relative;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      transition-property: transform;
      width: 18px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

}
.switch-box{
  // .text--secondary {
  //   color: #fff!important;
  // }
  .first-secondary{
    position: relative;
    left: 25px;
  }
  .second-secondary{
    position: relative;
    left: -40px;
  }
  .text--secondary {
    color: rgba(255, 255, 255, 1) !important;
  }
  // .v-input--switch__track {
  //   border-radius: 8px;
  //   width: 39px;
  //   height: 20px;
  //   left: 2px;
  //   position: absolute;
  //   opacity: 1;
  //   right: 2px;
  //   top: calc(50% - 10px);
  //   background-color: #999;

  // }
  #switch-des{
    color: rgba(255, 255, 255, 1) !important;
    z-index: 1;
    pointer-events: none;
    // z-index: 1;
  }
  .v-application.theme--light .v-input--switch:not(v-input--switch--flat):not(v-input--switch--inset) .v-input--switch__thumb {
    z-index: 22;
    box-shadow: 0 1px 3px 0 rgba(19, 17, 32, 1), 0 2px 1px -1px rgba(19, 17, 32,1), 0 1px 1px 0 rgba(19, 17, 32,1);
  }
  .v-input--switch__thumb {
    transform: translate(20px, 0) !important;
    z-index: 22;
  }
}

.edit-des{
  display: inline-block;
  padding-top: 5px;
  width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.w200 .form-datepicker[data-v-15ce9cbc] .v-input__slot{
  width: 250px;
}
</style>
