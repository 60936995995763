import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"link-box"},[(_vm.channels.length>1)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('a',{staticClass:"link-text",attrs:{"href":_vm.channel.link,"target":"_blank","title":_vm.channel.channelName}},[(_vm.channel.platform)?_c('XwPlatform',{attrs:{"platform":_vm.channel.platform,"show-name":false}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.channel.channelName || '-'))])],1)])]}}],null,false,3017256556)},[_c('span',_vm._l((_vm.channels),function(item,index){return _c('a',{key:index,staticClass:"link-self-text",attrs:{"href":item.link,"target":"_blank","title":item.channelName}},[(item.platform)?_c('XwPlatform',{attrs:{"platform":item.platform,"show-name":false}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.channelName || '-'))])],1)}),0)]):(_vm.channel.link)?_c('a',{staticClass:"link-text",attrs:{"href":_vm.channel.link,"target":"_blank","title":_vm.channel.channelName}},[(_vm.channel.platform)?_c('XwPlatform',{attrs:{"platform":_vm.channel.platform,"show-name":false}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.channel.channelName || '-'))])],1):(!_vm.channel.link)?_c('div',{staticClass:"link-default",attrs:{"title":_vm.channel.channelName}},[(_vm.channel.platform)?_c('XwPlatform',{attrs:{"platform":_vm.channel.platform,"show-name":false}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.channel.channelName || '-'))])],1):_vm._e(),(_vm.isChild)?_c('span',{staticClass:"link-child"},[_vm._v("子")]):_vm._e(),(_vm.isSelf)?_c(VTooltip,{attrs:{"top":"","close-delay":1000},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"link-self"},'span',attrs,false),on),[_vm._v("自带")])]}}],null,false,3877347470)},[_c('div',{staticClass:"link-self-box"},[(_vm.selfChannel.link)?_c('a',{staticClass:"link-self-text",attrs:{"href":_vm.selfChannel.link,"target":"_blank","title":_vm.selfChannel.channelName}},[(_vm.selfChannel.platform)?_c('XwPlatform',{attrs:{"platform":_vm.selfChannel.platform,"show-name":false}}):_vm._e(),_vm._v(_vm._s(_vm.selfChannel.channelName || '-')+" ")],1):_c('div',{staticClass:"link-self-default",attrs:{"title":_vm.selfChannel.channelName}},[(_vm.selfChannel.platform)?_c('XwPlatform',{attrs:{"platform":_vm.selfChannel.platform,"show-name":false}}):_vm._e(),_vm._v(_vm._s(_vm.selfChannel.channelName || '-')+" ")],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }