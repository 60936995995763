export const TAG_CONFIG_ITEMS = [
  {
    bgColor: 'rgba(44,160,248,0.06)',
    bdColor: 'rgba(44, 160, 248, 0.20)',
    color: 'rgba(44, 160, 248, 1)',
    key: 'pending',
  },
  {
    bgColor: 'rgba(242,69,69,0.06)',
    bdColor: 'rgba(242, 69, 69, 0.20)',
    color: 'rgba(242, 69, 69, 1)',
    key: 'danger',
  },
  {
    bgColor: 'noen',
    bdColor: 'rgba(255, 168, 15, 1)',
    color: 'rgba(255, 168, 15, 1)',
    key: 'waring',
  },
  {
    bgColor: 'rgba(255,255,255,1)',
    bdColor: 'rgba(145, 85, 253, 1)',
    color: 'rgba(145, 85, 253, 1)',
    key: 'normal',
  },
]
