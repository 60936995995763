<script>
import {
  defineComponent, computed, ref,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { exportFailure } from '../../api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    activeItem: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const dataAll = ref({
      total: props.currItem.data.total,
      success: props.currItem.data.success,
      failure: props.currItem.data.failure,
    })

    const handleClose = () => {
      emit('input', false)
    }

    const { message } = useMessage()

    const handleDownloadTemplate = async () => {
      message.warning('报表导出中！')
      const { data } = await exportFailure({ fileId: props.currItem.data.fileId, activeItem: props.activeItem})
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
      const url = window.URL.createObjectURL(blob) // 创建一个临时的url指向blob对象
      const a = document.createElement('a')
      a.href = url
      a.download = `线索${new Date().toLocaleString().replaceAll('/', '-')}审核表`
      a.click()
      window.URL.revokeObjectURL(url)
      message.success('导出完成！')
    }
    const handerDown = () => {
      handleDownloadTemplate()
    }

    return {
      dataAll,
      showDialog,
      handleClose,
      handerDown,
      handleDownloadTemplate,

    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="400"
  >
    <v-card class="xlsx-form-box">
      <h3 class="form-title">
        导入结果
        <v-btn
          icon
          small
          title="关闭"
          style="position: absolute; top: 16px; right: 20px;"
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </h3>
      <v-form
        class="current-info"
      >
        <div class="data-des" v-show="dataAll.total !=dataAll.success">本次共导入线索&nbsp;{{ dataAll.total }}&nbsp;条，其中成功&nbsp;{{ dataAll.success }}&nbsp;条，失败&nbsp;<span>{{ dataAll.failure }}</span>&nbsp;条，请检查填写内容是否符合规范！</div>
        <div class="data-des" v-show="dataAll.total ===dataAll.success">本次共导入线索&nbsp;{{ dataAll.total }}&nbsp;条，全部成功！</div>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          depressed
          class="btn-item mr-2"
          v-show="dataAll.total && dataAll.total!=0 && dataAll.total !=dataAll.success"
          @click="handerDown"
        >
        下载失败文件
        </v-btn>
        <v-btn
          color="primary"
          @click="handleClose"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.xlsx-form-box{
  padding: 20px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 16px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  label{
    display: block;
    width: 94px;
    text-align: left;
    height: 34px;
    color: rgba(0, 0, 0, 0.85);
  }

  .current-info{
    padding: 20px 20px 0 20px;
    ;
    .data-des{
      font-family: PingFang-SC, PingFang-SC;
      color: rgba(0,0,0,0.85);
      font-size: 14px;
      line-height: 28px;
      padding-left: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
      span{
        color: red;
      }
    }
  }
  .handerDown{
    // color: #9155fd;
    color: rgb(2, 167, 240);
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .btn-item{
    font-weight: 400;
  }
}
</style>
