import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import {
  domPlatformOptions, operationModeOptions, contractStatusOptions, authorizeRegionOptions, authorizePlatformOptions,
} from '@core/utils/options'
import { dateGen, oneMonth } from '@core/utils/dataProcess'
import { getCategory } from '@/views/audit/clueAudit/api'
import { getPage, getExport } from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(0)
  const categoryOptions = ref([])

  const { message } = useMessage()

  // 查询初始化参数
  const queryDataOri = {
    copyrightHolder: null,
    platform: null,
    channelName: null,
    categoryValue: null,
    operationModeValue: null,
    authorizeRegionValue: null,
    authorizePlatformValue: null,
    beginTime: dateGen(new Date(new Date() - oneMonth)),
    endTime: dateGen(new Date()),
    contractStatusValue: null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const searchConfig = computed(() => [
    {
      element: 'VTextField',
      props: 'copyrightHolder',
      placeholder: '版权方',
      label: '版权方',
    },
    {
      element: 'VAutocomplete',
      props: 'platform',
      placeholder: '平台',
      label: '平台',
      items: domPlatformOptions,
      multiple: false,
      itemText: 'label',
      itemValue: 'value',
    },
    {
      element: 'VTextField',
      props: 'channelName',
      placeholder: '频道',
      label: '频道',
    },
    {
      element: 'VCascader',
      props: 'categoryValue',
      placeholder: '垂类',
      label: '垂类',
      items: categoryOptions.value,
      multiple: false,
      itemText: 'name',
      itemValue: 'id',
    },
    {
      element: 'VAutocomplete',
      props: 'authorizeRegionValue',
      placeholder: '授权区域（地区）',
      label: '授权区域（地区）',
      items: authorizeRegionOptions,
      itemText: 'text',
      itemValue: 'value',
      multiple: false,
    },
    {
      element: 'VAutocomplete',
      props: 'authorizePlatformValue',
      placeholder: '授权平台',
      label: '授权平台',
      items: authorizePlatformOptions,
      itemText: 'text',
      itemValue: 'value',
      multiple: false,
    },
    {
      element: 'VAutocomplete',
      props: 'operationModeValue',
      placeholder: '运营方式',
      label: '运营方式',
      items: operationModeOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'DateRangePickers',
      props: 'createAt',
      searchKey: ['beginTime', 'endTime'],
      initValue: [queryDataOri.beginTime, queryDataOri.endTime],
      placeholder: '创建时间',
      label: '创建时间',
      clearable: true,
      range: true,
    },
    {
      element: 'VAutocomplete',
      props: 'contractStatusValue',
      placeholder: '合约状态',
      label: '合约状态',
      items: contractStatusOptions,
      multiple: false,
    },
  ])

  // 获取垂类
  const fetchCategory = async () => {
    const {
      data: { data = [], status, message: messageText },
    } = await getCategory()
    if (status === 200) {
      categoryOptions.value = data || []
    } else {
      message.error(messageText)
    }
  }

  const fetchList = async () => {
    loading.value = true
    try {
      const {
        data: {
          data = [], status, total, message: messageText,
        },
      } = await getPage({
        page: page.value,
        pageSize: pageSize.value,
        ...queryData,
      })
      loading.value = false
      if (status === 200) {
        pageList.value = data
        pageLength.value = total
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err.response.data.message)
      loading.value = false
    }
  }

  const handleExport = async () => {
    if (pageLength.value <= 0) {
      message.error('您没有可导出的内容')

      return
    }

    if (pageLength.value >= 10000) {
      message.error('请缩小筛选范围，系统只允许导出10000条数据')

      return
    }
    message.warning('报表导出中！')
    const { data } = await getExport({ ...queryData })
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
    const url = window.URL.createObjectURL(blob) // 创建一个临时的url指向blob对象
    const a = document.createElement('a')
    a.href = url
    a.download = `在 ${new Date().toLocaleString().replaceAll('/', '-')} 导出的注册表`
    a.click()
    window.URL.revokeObjectURL(url)
    message.success('导出完成！')
  }

  const search = async params => {
    page.value = 1
    Object.assign(queryData, params)

    fetchList()
  }

  const handleRefresh = () => {
    fetchList()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
  }

  onMounted(() => {
    fetchList()
    fetchCategory()
  })

  return {
    searchConfig,
    loading,
    pageList,
    page,
    pageSize,
    pageLength,
    categoryOptions,

    search,
    fetchList,
    handleRefresh,
    handleSizeChange,
    handleCurrentChange,
    handleExport,
  }
}
