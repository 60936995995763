<script>
import {
  defineComponent, computed, ref,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { required, ytChannelIdValidator } from '@core/utils/validation'
import DateRangePickers from '@core/components/xw-form/DateRangePickers.vue'
import {
  channelMultipleOptions, contentClass, homepageProperies, operationGroupOptionsByYtnews,
} from '@core/utils/options'
import { register } from '../api'

export default defineComponent({
  components: {
    DateRangePickers,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: String,
      default: 'youtube',
    },
  },
  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const formRef = ref()
    const step = ref(1)
    const isYoutube = computed(() => props.active === 'youtube')
    const isNewAdd = computed(() => props.currItem.registerTypeId === 0)

    const formData = ref({
      channelId: props.currItem.oldChannelId || null, // UC-9-kyTW8ZkZNDHQJ6FgpwQ
      channelName: props.currItem.oldChannelName || null,
      operationTeam: operationGroupOptionsByYtnews.find(x => x.text === props.currItem.operationTeam)?.value,
      operationTeamName: props.currItem.operationTeam,
      operationMode: props.currItem.operationMode || null,
      operatingModeName: props.currItem.operationMode || null,
      collectionType: props.currItem.collectionType !== undefined ? props.currItem.collectionType : null,
      registerDate: null,
      contentType: props.currItem.contentType || null,
      categoryId: props.currItem.categoryId || null,
      categoryName: props.currItem.categoryName || null,
      registerPlatform: props.active,
      pageAttribute: ['self_acc_country', 'self_not_acc_country'].includes(props.currItem.pageAttributeCode) ? props.currItem.pageAttributeCode : null,
      type: isYoutube.value ? 0 : 1, // 0 youTube 1 facebook
      operationModeId: props.currItem.operationModeId,
    })

    const { message, MessageBox } = useMessage()

    const handleClose = () => {
      emit('input', false)
    }

    const handleSave = async () => {
      const { operationMode, operationTeam, ...others } = formData.value
      const { data: { data, status, message: messageText } } = await register({
        id: props.currItem.id,
        ...others,
      })

      if (status === 200) {
        message.success(data || '提交成功')
        handleClose()
        emit('success', { currItem: props.currItem, formData: formData.value })
      } else {
        message.error(messageText)
      }
    }

    const channels = computed(() => props.currItem.channels)

    const handleSubmit = () => {
      const valid = formRef.value.validate()
      if (!valid) return

      // 若频道不是合集，不可以绑定多个签约作品
      if (formData.value.collectionType === 1 && channels.value.length > 1) {
        message.error(`注册频道/主页${formData.value.channelName}不是合集，不可以绑定多个签约频道/作品`)

        return
      }

      MessageBox({
        title: '提示',
        message: '确认后系统将提交至下一环节。请确认已经完成注册频道/主页，建立发布通道，邀请频道/主页给相关运营人员',
        type: 'warning',
        showCancelButton: true,
      }).then(() => {
        handleSave()
      })
    }

    const handleNextStep = () => {
      const valid = formRef.value.validate()
      if (!valid) return
      step.value = 2
    }

    return {
      showDialog,
      formRef,
      required,
      ytChannelIdValidator,
      channelMultipleOptions,
      formData,
      step,
      isYoutube,
      channels,
      isNewAdd,
      homepageProperies: homepageProperies.filter(x => ['self_acc_country', 'self_not_acc_country'].includes(x.value)),
      contentClass,
      handleClose,
      handleSubmit,
      handleNextStep,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    :width="step === 1 ? 400 : 500"
  >
    <v-form ref="formRef">
      <v-card
        v-if="step === 1"
        class="form-box"
      >
        <h3 class="form-title">
          注册：{{ currItem.registerType }}
          <v-btn
            icon
            small
            title="关闭"
            style="position: absolute; top: 16px; right: 20px;"
            @click="handleClose"
          >
            <v-icon size="22">
              mdi-close
            </v-icon>
          </v-btn>
        </h3>
        <div

          class="current-info"
        >
          <div class="current-item">
            <label>{{ isYoutube ? '频道ID' : '主页ID' }}</label>
            <div
              v-if="!isNewAdd"
              class="current-text"
            >
              {{ currItem.oldChannelId }}
            </div>
            <div v-else>
              <v-text-field
                v-model.trim="formData.channelId"
                :placeholder="isYoutube ? '频道ID' : '主页ID'"
                style="width: 250px"
                hide-details="auto"
                dense
                outlined
                :rules="[required, isYoutube ? ytChannelIdValidator: () => true]"
                clearable
              ></v-text-field>
            <!-- :rules="[required, isYoutube ? ytChannelIdValidator: () => true]" -->
            </div>
          </div>
          <div class="current-item">
            <label>{{ isYoutube ? '频道名' : '主页名' }}</label>
            <div
              v-if="!isNewAdd"
              class="current-text"
            >
              {{ currItem.oldChannelName }}
            </div>
            <div v-else>
              <v-text-field
                v-model.trim="formData.channelName"
                :placeholder="isYoutube ? '频道名' : '主页名'"
                style="width: 250px"
                hide-details="auto"
                :rules="[required]"
                dense
                outlined
                clearable
              ></v-text-field>
            </div>
          </div>
          <div class="current-item">
            <label>运营团队</label>
            <div class="current-text">
              {{ formData.operationTeamName }}
            </div>
          </div>
          <div
            v-if="isYoutube"
            class="current-item"
          >
            <label>运营类型</label>
            <div class="current-text">
              {{ formData.operatingModeName }}
            </div>
          </div>
          <div
            class="current-item"
          >
            <label>垂类</label>
            <div class="current-text">
              {{ formData.categoryName }}
            </div>
          </div>
          <div
            v-if="!isYoutube"
            class="current-item"
          >
            <label>主页属性</label>
            <div>
              <!--代运营、代运营+制作时只读--->
              <div
                v-if="formData.operationModeId === 0 || formData.operationModeId === 2"
                class="current-text"
              >
                {{ formData.operationMode }}
              </div>
              <v-autocomplete
                v-else
                v-model.trim="formData.pageAttribute"
                :items="homepageProperies"
                no-data-text="暂无数据"
                hide-details
                placeholder="主页属性"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>
          <div
            v-if="!isYoutube && isNewAdd"
            class="current-item"
          >
            <label>内容分类</label>
            <div>
              <v-autocomplete
                v-model.trim="formData.contentType"
                :rules="[required]"
                :items="contentClass"
                no-data-text="暂无数据"
                hide-details
                placeholder="内容分类"
                dense
                outlined
                clearable
                item-text="text"
                item-value="value"
                style="width: 250px"
              ></v-autocomplete>
            </div>
          </div>
          <div class="current-item">
            <label>单开/合集</label>
            <v-radio-group
              v-model="formData.collectionType"
              :rules="[required]"
              hide-details="true"
            >
              <v-radio
                v-for="item in channelMultipleOptions"
                :key="item.value"
                :label="item.text"
                :value="item.value"
                :ripple="false"
              ></v-radio>
            </v-radio-group>
          </div>
          <div
            v-if="isNewAdd"
            class="current-item"
          >
            <label>注册时间</label>
            <div>
              <DateRangePickers
                v-model="formData.registerDate"
                :range="false"
                placeholder="注册时间"
                clearable
                no-title
              />
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            depressed
            outlined
            @click="handleClose"
          >
            取消
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="handleNextStep"
          >
            下一步
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-if="step === 2"
        class="form-box"
      >
        <h3 class="form-title">
          建立通道
          <v-btn
            icon
            small
            title="关闭"
            style="position: absolute; top: 16px; right: 20px;"
            @click="handleClose"
          >
            <v-icon size="22">
              mdi-close
            </v-icon>
          </v-btn>
        </h3>
        <div
          class="build-box"
        >
          <div class="build-item">
            <div class="build-sign">
              <div class="build-name">
                签约频道/作品名
              </div>
              <div class="sign-channels">
                <a
                  v-for="row in channels"
                  :key="row.id"
                  :href="row.link"
                  target="_blank"
                  class="sign-item text--link"
                >
                  <XwPlatform
                    :platform="row.platform"
                    :show-name="false"
                  />{{ row.channelName }}
                </a>
              </div>
            </div>
          </div>
          <div class="form-arro"></div>
          <div class="build-item">
            <div class="build-name">
              注册频道/主页
            </div>
            <div class="register-channel">
              <XwPlatform
                :platform="isYoutube ? 'YouTube':'Facebook'"
                :show-name="false"
              /><span class="overflow">{{ formData.channelName }}</span>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            depressed
            outlined
            @click="handleClose"
          >
            取消
          </v-btn>
          <v-btn
            color="secondary"
            text
            depressed
            outlined
            @click="step = 1"
          >
            上一步
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="handleSubmit"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 20px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  label{
    display: block;
    width: 94px;
    text-align: left;
    height: 34px;
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.85);
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .form-arro{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 14px;
    transform: translate(-50%, -50%);
    display: block;
    background: url('../../../../assets/images/icons/arro_rt.png');
  }
  .current-info{
    padding: 20px 20px 0 20px;
    font-size: 14px;
    .current-item{
      display: flex;
      align-items: left;
      margin-bottom: 10px;
      ::v-deep .v-input--selection-controls{
        margin-top: -2px;
      }
      ::v-deep .v-input--radio-group--column{
        height: 36px;
        display: flex;
        align-items: center;
      }
      ::v-deep .v-input--radio-group__input{
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        .v-radio{
          margin-right: 20px;
        }
      }
      ::v-deep .v-input--selection-controls{
        margin-top: -2px;
      }
      ::v-deep .v-label{
        font-size: 14px;
      }
      ::v-deep .v-input--radio-group__input{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .v-radio{
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
      ::v-deep .v-input--selection-controls__input{
        width: 16px;
        height: 16px;
      }
      .radio-item{
        display: flex;
        align-items: center;
        label{
          width: 50px;
          text-align: left;
        }
      }
    }
  }
  .current-text{
    padding: 7px 0;
  }
  .build{
    &-box{
      display: flex;
      position: relative;
      padding: 20px 24px;
      font-size: 14px;
    }
    &-item{
      width: 200px;
      padding: 20px;
      min-height: 250px;
      background: #F6F7F8;
      border-radius: 8px;
      box-sizing: border-box;
    }
    &-item:first-child{
      margin-right: 52px;
    }
    &-header{
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      margin-bottom: 34px;
      height: 20px;
      font-weight: bold;
    }
    &-title{
      color: rgba(0,0,0,0.6);
    }
  }
  .sign{
    &-channels{
      padding: 4px 0;
      display: flex;
      flex-direction: column;
    }
    &-item{
      margin: 2px 0;
      border-radius: 4px;
      background: #F6F7F8;
      height: 22px;
      line-height: 22px;
      display: flex;
      align-items: center;
      width: fit-content;
    }
  }
  .register-channel{
      width: 160px;
      height: 32px;
      border-radius: 6px;
      padding: 8px;
      margin-top: 8px;
      border: 1px solid rgba(0,0,0,0.25);
      color: rgba(0,0,0,0.6);
      display: flex;
      line-height: 16px;
    }
  .text--link{
    text-decoration: none;
    color: rgba(0,0,0,0.8)!important;
  }
  .text--link:hover{
    text-decoration: none;
    color: #2ca0f8!important;
  }
}
</style>
