import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0"}},[_c('XwTabs',{attrs:{"tabs":_vm.tabsConfig,"active":_vm.active},on:{"change":_vm.handleTabChange}}),_c('div',{staticStyle:{"padding":"12px 20px"}},[_c('XwSearch',{ref:"searchRef",attrs:{"form-config":_vm.searchConfig},on:{"search":_vm.handerSearch}})],1),_c('div',{staticStyle:{"padding":"0 20px 12px 20px","position":"relative"}},[_c(VBtn,{staticClass:"btn-item mr-3",attrs:{"outlined":"","depressed":""},on:{"click":_vm.handerConfirmAgain}},[_vm._v(" 导出 ")]),(_vm.auditAuth)?_c(VBtn,{staticClass:"btn-item mr-3",attrs:{"outlined":""},on:{"click":_vm.handerImport}},[_vm._v(" 导入 ")]):_vm._e(),(!_vm.showXlsx)?_c('input',{ref:"fileInputRef",attrs:{"type":"file","accept":".xls,.xlsx","hidden":true},on:{"change":_vm.handleChange}}):_vm._e()],1),_c('div',{staticStyle:{"padding":"0 20px"}},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"rows":_vm.pageList},on:{"selectChange":_vm.selectChange,"clearCheckboxRow":_vm.clearCheckboxRow},scopedSlots:_vm._u([{key:"channelName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"padding-left":"15px","display":"flex","align-items":"center"}},[(row.reviewReason)?_c('Tag',{staticStyle:{"margin-left":"-25px","margin-right":"5px"},attrs:{"tag-name":"复","tag-key":"waring"}}):_vm._e(),_c('div',{staticClass:"overflow"},[_c('a',{staticClass:"text--link",staticStyle:{"text-decoration":"none"},attrs:{"href":row.channelLink,"target":"_blank"}},[_vm._v(_vm._s(row.channelName || '-'))])])],1)]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"padding-left":"15px","display":"flex","align-items":"center"}},[(row.reviewReason)?_c('Tag',{staticStyle:{"margin-left":"-25px","margin-right":"5px"},attrs:{"tag-name":"复","tag-key":"waring"}}):_vm._e(),_c('span',[_vm._v(_vm._s(row.name || '-'))])],1)]}},{key:"platform",fn:function(ref){
var row = ref.row;
return [_c('XwPlatform',{attrs:{"platform":row.platform,"show-name":true}})]}},{key:"link",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"text--link overflow",staticStyle:{"text-decoration":"none"},attrs:{"href":row.link,"target":"_blank"}},[_vm._v(_vm._s(row.link || '-'))])]}},{key:"auditStatus",fn:function(ref){
var row = ref.row;
return [(_vm.resolveAuditStatusText(row.auditStatus))?_c('XwStatus',{attrs:{"status":row.auditStatus,"text":_vm.resolveAuditStatusText(row.auditStatus)}}):_c('span',[_vm._v("-")])]}},{key:"reviewReason",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.reviewReason || '-')+" "),(row.reviewRemark)?_c('span',[_vm._v("("+_vm._s(row.reviewRemark)+")")]):_vm._e()])]}},{key:"auditResult",fn:function(ref){
var row = ref.row;
return [(row.auditResult && row.auditStatus !== 2)?_c('div',{staticStyle:{"display":"flex"}},[_c('XwStatus',{attrs:{"status":row.auditResult === '通过' ? 1: 3,"text":row.auditResult}}),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"ml-1 iconfont icon-a-xiangqing2x2"},'span',attrs,false),on))]}}],null,true)},[(row.auditResult)?_c('div',{staticClass:"audit-result"},[_c('div',{staticClass:"result-inner"},[_c('div',{staticClass:"inner-item"},[_vm._v(" YT: "),_c('XwStatus',{staticClass:"ml-2",attrs:{"status":row.auditDetailResult === 0 || row.auditDetailResult === 1 ? 1: 3,"text":row.auditDetailResult === 0 || row.auditDetailResult === 1 ? '通过': '拒绝'}}),(row.ytCollectionType || row.ytRejectReason)?_c('span',[_vm._v("("+_vm._s(row.auditDetailResult === 0 || row.auditDetailResult === 1 ? row.ytCollectionType : row.ytRejectReason)+")")]):_vm._e()],1),_c('div',{staticClass:"inner-item"},[_vm._v(" FB: "),_c('XwStatus',{staticClass:"ml-2",attrs:{"status":row.auditDetailResult === 0 || row.auditDetailResult === 2 ? 1: 3,"text":row.auditDetailResult === 0 || row.auditDetailResult === 2 ? '通过': '拒绝'}}),(row.fbCollectionType || row.fbRejectReason)?_c('span',[_vm._v(" ("+_vm._s(row.auditDetailResult === 0 || row.auditDetailResult === 2 ? row.fbCollectionType: row.fbRejectReason)+") ")]):_vm._e()],1)]),_c('div',{staticClass:"result-remark"},[_c('label',[_vm._v("备注：")]),_c('div',[_vm._v(_vm._s(row.auditRemark || '-'))])])]):_vm._e()])],1):_c('span',[_vm._v("-")])]}},{key:"channelLevel",fn:function(ref){
var row = ref.row;
return [(row.channelLevel && row.auditStatus !== 2)?_c('div',[_vm._v(" "+_vm._s(row.channelLevel)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"level",fn:function(ref){
var row = ref.row;
return [(row.level && row.auditStatus !== 2)?_c('div',[_vm._v(" "+_vm._s(row.level)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"auditedUserName",fn:function(ref){
var row = ref.row;
return [(row.auditedUserName && row.auditStatus !== 2)?_c('div',[_vm._v(" "+_vm._s(row.auditedUserName)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"auditedAt",fn:function(ref){
var row = ref.row;
return [(row.auditedAt && row.auditStatus !== 2)?_c('div',[_vm._v(" "+_vm._s(row.auditedAt)+" ")]):_c('div',[_vm._v(" - ")])]}},{key:"opts",fn:function(ref){
var row = ref.row;
return [(_vm.auditAuth)?_c('a',{on:{"click":function($event){return _vm.handleAudit(row)}}},[_vm._v(" 审核 ")]):_vm._e()]}}])}),(_vm.pageLength)?_c('div',[_c('new-pagination',{attrs:{"page-index":_vm.page,"page-size":_vm.pageSize,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1),(_vm.showDialog)?_c('Audit',{attrs:{"active-item":_vm.activeItem,"curr-item":_vm.currItem},on:{"success":_vm.handleRefresh},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e(),(_vm.showXlsx)?_c('importXlsx',{attrs:{"curr-item":_vm.fileData,"active-item":_vm.activeItem},on:{"success":_vm.handleRefresh},model:{value:(_vm.showXlsx),callback:function ($$v) {_vm.showXlsx=$$v},expression:"showXlsx"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }