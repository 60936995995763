<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-use-before-define -->
<script>
import {
  defineComponent, computed, ref, reactive, watch,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { channelMultipleOptions } from '@core/utils/options'
import { required } from '@core/utils/validation'
import { channelImportProfit } from '@/api/accountHomePageAdd'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const subsetType = ref(props.currItem.collectionTypeValue)
    const formRef = ref()
    const radioMap = ref(channelMultipleOptions)

    const formData = reactive({
      taskName: null,
      file: null,
    })

    const fileName = ref(null)
    const fileInputRef = ref()

    const { message } = useMessage()

    const handleClose = () => {
      emit('input', false)
    }

    const handleSubmit = async () => {
      if (formRef.value.validate()) {
        const form = new FormData()
        form.append('file', formData.file)
        try {
          const { data: { status, message: messageText, data } } = await channelImportProfit({
            taskName: formData.taskName,
            form,
          })
          if (status === 200) {
            message.success('提交成功')
            emit('success')
            handleClose()
          } else {
            message.error(data.message)
          }
        } catch (err) {
          message.error(err.response.data.message)
        }
      }
    }

    const handleChange = file => {
      if (!file?.target?.files.length) return
      const { target: { files: [targetFile = {}] } } = file
      formData.file = targetFile
      fileName.value = targetFile.name
    }

    const handleFileChange = val => {
      if (!val) {
        formData.file = null
        fileName.value = null
      }
    }

    watch(fileName, val => {
      if (!val) {
        fileInputRef.value.value = null
      }
    })

    const handleClick = () => {
      fileInputRef.value.click()
    }

    const importXlsx = () => {
      console.log('importXlsx')
    }

    const handerDown = () => {
      handleDownloadTemplate()
    }

    // const importXlsx =() =>
    const handleDownloadTemplate = () => {
      const link = document.createElement('a')
      link.download = '获利状态模版.xlsx' // 下载文件名称
      link.href = `${process.env.BASE_URL}makexlsx.xlsx` // 下载地址
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    return {
      showDialog,
      radioMap,
      subsetType,
      formRef,
      required,
      handleClose,
      handleSubmit,
      importXlsx,
      handerDown,
      handleDownloadTemplate,

      formData,
      fileName,
      fileInputRef,
      handleChange,
      handleFileChange,
      handleClick,

    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="500"
  >
    <v-card class="xlsx-form-box">
      <h3 class="form-title">
        导入获利状态
        <v-btn
          icon
          small
          title="关闭"
          style="position: absolute; top: 16px; right: 20px;"
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </h3>
      <v-form
        ref="formRef"
        class="current-info"
      >
        <div class="current-item">
          <div
            class="handerDown"
            @click="handerDown"
          >
            获利状态模板下载
          </div>
        </div>
        <div class="current-item">
          <div class="form-item upload-field-boxs">
            <!-- <label>导入获利状态</label> -->
            <!-- :rules="[validators.required]" -->
            <!-- prepend-icon="mdi-cloud-upload" -->
            <v-text-field
              v-model.trim="fileName"
              :rules="[required]"
              label=""
              placeholder="导入获利状态"
              hide-details="auto"
              class="mb-6"
              dense
              outlined
              readonly
              clearable
              single-line
              @click="handleClick"
              @change="handleFileChange"
            ></v-text-field>
            <input
              ref="fileInputRef"
              :hidden="true"
              type="file"
              accept=".xls,.xlsx"
              @change="handleChange"
            />
          </div>

          <!-- <label>合集/单开</label>
          <div>
            <v-radio-group
              v-model="subsetType"
              :rules="[required]"
              small
            >
              <v-radio
                v-for="item in radioMap"
                :key="item.value"
                :label="item.text"
                :value="item.value"
                :ripple="false"
              ></v-radio>
            </v-radio-group> -->
          <!-- </div> -->
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="handleClose"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          @click="handleSubmit"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.xlsx-form-box{
  padding: 20px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  label{
    display: block;
    width: 94px;
    text-align: left;
    height: 34px;
    color: rgba(0, 0, 0, 0.85);
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .current-info{
    padding: 20px 20px 0 20px;
    font-size: 14px;
    .current-item{
      display: flex;
      align-items: left;
      margin-bottom: 10px;
      ::v-deep .v-input--selection-controls{
        margin-top: -2px;
      }
      ::v-deep .v-input--radio-group__input{
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        .v-radio{
          margin-right: 20px;
        }
      }
      ::v-deep .v-input--selection-controls{
        margin-top: -2px;
      }
      ::v-deep .v-label{
        font-size: 14px;
      }
      ::v-deep .v-input--radio-group__input{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .v-radio{
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
      ::v-deep .v-input--selection-controls__input{
        width: 16px;
        height: 16px;
      }
      .radio-item{
        display: flex;
        align-items: center;
        label{
          width: 50px;
          text-align: left;
        }
      }
    }
  }
  .handerDown{
    color: #9155fd;
    cursor: pointer;
  }

}

</style>
<style lang="scss">
.upload-field-boxs{
  .v-input__append-outer .v-icon, .v-input__prepend-outer .v-icon {
    color: #9155fd;
  }
}
</style>
