import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import {
  auditStatusOptions, auditResultOptions, domPlatformOptions, auditResultDetailOptions, channelOriginOptions, channelRatingOptions, trueOrFalseOptions, fieldOptions, clueChannelRatingOptions,
} from '@core/utils/options'
import { getPage, getAuidtCount, getCategory } from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(0)
  const active = ref('channel')
  const categoryOptions = ref([])
  const auditCountParams = ref({})
  const activeItem = ref({
    key: 'channel',
  })
  const searchRef = ref()

  const { message } = useMessage()

  // 查询初始化参数
  const queryDataOri = {
    platform: null,
    channelName: null,
    category: null,
    mcnOrg: null,
    source: null,
    auditStatus: 0, // 默认待审核
    auditResult: null,
    channelLevel: null,
    auditBeginTime: null,
    auditEndTime: null,
    reviewFlag: null, // 复审
    level: null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const searchConfig = computed(() => [
    {
      element: 'VTextField',
      props: 'channelName',
      placeholder: '频道',
      label: '频道',
      active: ['channel'],
    },
    {
      element: 'VAutocomplete',
      props: 'platform',
      placeholder: '平台',
      label: '平台',
      items: domPlatformOptions,
      multiple: false,
      active: ['channel'],
      itemText: 'label',
      itemValue: 'value',
    },
    {
      element: 'VTextField',
      props: 'name',
      placeholder: '作品名',
      label: '作品名',
      active: ['video', 'image', 'audio'],
    },
    {
      element: 'VAutocomplete',
      props: 'auditStatus',
      placeholder: '审核状态',
      label: '审核状态',
      items: auditStatusOptions,
      initValue: 0,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'VCascader',
      props: 'categoryValue',
      placeholder: '垂类',
      label: '垂类',
      items: fieldOptions, // categoryOptions.value,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'VTextField',
      props: 'mcnOrg',
      placeholder: 'MCN机构',
      label: 'MCN机构',
      active: ['channel'],
    },
    {
      element: 'VAutocomplete',
      props: 'sourceValue',
      placeholder: '频道来源',
      label: '频道来源',
      items: channelOriginOptions,
      itemText: 'text',
      itemValue: 'value',
      multiple: false,
      active: ['channel'],
    },
    {
      element: 'VAutocomplete',
      props: 'reviewFlag',
      placeholder: '复审',
      label: '复审',
      items: trueOrFalseOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'VAutocomplete',
      props: 'auditResult',
      placeholder: '审核结果',
      label: '审核结果',
      items: auditResultOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'DateRangePickers',
      props: 'createdAt',
      searchKey: ['beginTime', 'endTime'],
      initValue: [null, null],
      placeholder: '创建时间',
      label: '创建时间',
      clearable: true,
      range: true,
    },
    {
      element: 'VAutocomplete',
      props: 'channelLevel',
      placeholder: '频道评级',
      label: '频道评级',
      items: channelRatingOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: ['channel'],
    },
    {
      element: 'VAutocomplete',
      props: 'level',
      placeholder: '频道评级',
      label: '频道评级',
      items: clueChannelRatingOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
      active: ['video', 'image', 'audio'],
    },
    {
      element: 'VAutocomplete',
      props: 'auditDetailResult',
      placeholder: '细分结果',
      label: '细分结果',
      items: auditResultDetailOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'DateRangePickers',
      props: 'auditAt',
      searchKey: ['auditBeginTime', 'auditEndTime'],
      initValue: [null, null],
      placeholder: '审核时间',
      label: '审核时间',
      clearable: true,
      range: true,
    },
  ])

  const fetchList = async () => {
    loading.value = true
    try {
      const {
        data: {
          data = [], status, total, message: messageText,
        },
      } = await getPage({
        page: page.value,
        pageSize: pageSize.value,
        activeItem: activeItem.value,
        ...queryData,
      })
      loading.value = false
      if (status === 200) {
        pageList.value = data
        pageLength.value = total
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err.response.data.message)
      loading.value = false
    }
  }

  const fetchAuditCount = async () => {
    try {
      const {
        data: { data, status, message: messageText },
      } = await getAuidtCount()
      if (status === 200) {
        auditCountParams.value = data
      } else {
        message.error(messageText)
      }
    } catch (err) {
      message.error(err.response.data.message)
    }
  }

  // 获取垂类
  const fetchCategory = async () => {
    const {
      data: { data = [], status, message: messageText },
    } = await getCategory()
    if (status === 200) {
      categoryOptions.value = data || []
    } else {
      message.error(messageText)
    }
  }

  const search = async params => {
    const { isOpen, ...others } = params
    page.value = 1
    Object.assign(queryData, others)

    fetchList()
    fetchAuditCount()
  }

  const handleRefresh = params => {
    pageList.value = pageList.value.map(item => {
      if (item.id === params.id) {
        return {
          ...item,
          ...params,
        }
      }

      return item
    })

    // fetchList()
    fetchAuditCount()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
    fetchAuditCount()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
    fetchAuditCount()
  }

  const handleTabChange = tabItem => {
    activeItem.value = tabItem
    active.value = tabItem.key
    page.value = 1
    searchRef.value.reset()
  }

  onMounted(() => {
    fetchList()
    fetchCategory()
    fetchAuditCount()
  })

  return {
    searchConfig,
    queryData,
    queryDataOri,
    loading,
    pageList,
    page,
    pageSize,
    pageLength,
    auditCountParams,
    active,
    activeItem,
    searchRef,

    search,
    fetchList,
    handleRefresh,
    handleSizeChange,
    handleCurrentChange,
    handleTabChange,
  }
}
