import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"400"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{staticClass:"form-box"},[_c('h3',{staticClass:"form-title"},[_vm._v(" 设置"),(_vm.currItem.channelName)?_c('span',[_vm._v("("+_vm._s(_vm.currItem.channelName)+")")]):_vm._e(),_c(VBtn,{staticStyle:{"position":"absolute","top":"16px","right":"20px"},attrs:{"icon":"","small":"","title":"关闭"},on:{"click":_vm.handleClose}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" mdi-close ")])],1)],1),_c(VForm,{ref:"formRef",staticClass:"current-info"},[_c('div',{staticClass:"current-item"},[_c('label',[_vm._v("垂类")]),_c('div',[_c('VCascader',{staticStyle:{"width":"300px"},attrs:{"items":_vm.categoryOptions,"item-text":"name","item-value":"id"},on:{"change":_vm.handleCategoryChange},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.handleClose}},[_vm._v(" 取消 ")]),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":_vm.handleSubmit}},[_vm._v(" 确认 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }