<script>
import {
  defineComponent, computed, ref,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { getSubset } from '../../api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const tableCols = ref([
      {
        text: '子集',
        sortable: false,
        value: 'registerChannelName',
        width: 120,
      },
      {
        text: '播放列表',
        sortable: false,
        value: 'playlist',
        width: 120,
        active: 'youtube',
      },
      {
        text: '标签',
        sortable: false,
        value: 'videoCustomTag',
        width: 120,
        active: 'facebook',
      },
      {
        text: '创建时间',
        sortable: false,
        value: 'createdAt',
        width: 120,
      },
    ])

    const tableColsCurr = computed(() => tableCols.value.filter(x => !x.active || x.active === props.active))

    const list = ref([])

    const { message } = useMessage()

    const fetchSubset = async () => {
      try {
        const {
          data: {
            data = [], status, message: messageText,
          },
        } = await getSubset({
          id: props.currItem.id,
        })
        if (status === 200) {
          list.value = data
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    fetchSubset()

    const handleClose = () => {
      emit('input', false)
    }

    return {
      showDialog,
      tableColsCurr,
      list,
      handleClose,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="500"
  >
    <v-card class="form-box">
      <h3 class="form-title">
        合集<span v-if="currItem.channelName">({{ currItem.channelName }})</span>
        <v-btn
          icon
          small
          title="关闭"
          style="position: absolute; top: 16px; right: 20px;"
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </h3>
      <div style="padding: 10px 20px 20px 20px">
        <vxe-table
          :data="list"
          border
          max-height="200"
        >
          <vxe-column
            v-for="item in tableColsCurr"
            :key="item.value"
            :field="item.value"
            :title="item.text"
          ></vxe-column>
        </vxe-table>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          style="min-width: 65px"
          outlined
          @click="handleClose"
        >
          关闭
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 20px 0 0 0;
  font-size: 14px;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
}
</style>
