import axios from '@axios'
import url from './url'
import config from '../../../../config'

const prefix = config.serviceUrl

/**
 * 获取线索审核列表
 * @param {page} 页码
 * @param {pageSize} 每页条数
 * @param {copyrightHolder} 版权方
 * @param {platform} 平台
 * @param {channelName} 频道
 * @param {categoryValue} 垂类
 * @param {operationModeValue} 运营模式
 * @param {authorizeRegionValue} 授权区域
 * @param {authorizePlatformValue} 授权平台
 * @param {beginTime} 开始时间
 * @param {endTime} 结束时间
 * @param {contractStatusValue} 合约状态
 */

export const getPage = params => axios.get(prefix + url.PAGE, { params })

/**
 * 设置垂类
 * @param {id} 列表id
 * @param {categoryId} 垂类
 * @param {parentCategoryId} 上级垂类
 */
export const setCategory = params => axios.get(prefix + url.SET_CATEGORY, { params })

/**
 * 导出
 */
export const getExport = params => axios.get(prefix + url.EXPORT, { params, responseType: 'blob' })
