<script>
import {
  defineComponent, ref, computed, onUnmounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { can } from '@core/utils/useAccess'
import ChannelLink from '@/views/ways/register/components/channelLink/Link.vue'
import { registerStatusOptions } from '@core/utils/options'
import { useRouter } from '@core/utils'
import tableCols from './tableCols'
import useSearch from './useSearch'
import MultipleMark from './components/MultipleMark.vue'
import Audit from './components/Audit.vue'

import Add from './components/Add.vue'
import { getExport } from './api'

export default defineComponent({
  components: {
    MultipleMark,
    Add,
    ChannelLink,
    Audit,
  },
  setup() {
    const currItem = ref({})
    const tableRef = ref()
    const checkRows = ref([])
    const showMultipleMark = ref(false)
    const markType = ref(null)
    const multiple = ref(false)
    const showAudit = ref(false)
    const showAdd = ref(false)
    const showDetail = ref(false)
    const showBtns = ref(false)
    const multipleRef = ref()
    const { router } = useRouter()

    const { message } = useMessage()

    const {
      queryDataOri, fetchList, searchConfig, queryData, pageList, pageLength, active, ...searchOthers
    } = useSearch()

    // 分tab处理table和search配置
    const tableColsFilter = computed(() => tableCols.filter(x => !x.active || x.active === active.value))
    const searchConfigFilter = computed(() => searchConfig.value.filter(x => !x.active || x.active === active.value))

    // 导出
    const handleExport = async () => {
      if (pageLength.value <= 0) {
        message.error('您没有可导出的内容')

        return
      }

      if (pageLength.value >= 10000) {
        message.error('请缩小筛选范围，系统只允许导出10000条数据')

        return
      }
      message.warning('报表导出中！')
      const { data } = await getExport({ ...queryData, authorizePlatform: active.value === 'youtube' ? 0 : 1 })
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
      const url = window.URL.createObjectURL(blob) // 创建一个临时的url指向blob对象
      const a = document.createElement('a')
      a.href = url
      a.download = `在 ${new Date().toLocaleString().replaceAll('/', '-')} 导出的注册表`
      a.click()
      window.URL.revokeObjectURL(url)
      message.success('导出完成！')
    }

    // 注册频道/主页
    const handleAdd = row => {
      showAdd.value = true
      currItem.value = row
    }

    // 详情
    const handleDetial = row => {
      router.push({ name: 'register-detail', query: { id: row.id } })
    }

    // 审核
    const handleAudit = row => {
      showAudit.value = true
      currItem.value = row

      // router.push({ name: 'register-audit', query: { id: row.id } })
    }

    const handleMultipleMark = type => {
      checkRows.value = tableRef.value.getCheckboxRecords()
      if (!checkRows.value.length) {
        message.warning('请先勾选数据')

        return
      }

      // 只有待建群和已完成才可标记
      const checkResult = checkRows.value.filter(x => ![4, 9].includes(x.status) || (type === 'channel' && x.backendSettingFlag === '是') || (type === 'banner' && x.bannerDesignFlag === '是'))
      if (checkResult.length) {
        message.error('仅对待建群、已完成状态，且未标记过的记录可以批量标记')

        return
      }

      markType.value = type
      multiple.value = true
      showMultipleMark.value = true
    }

    const handleMarkSuccess = () => {
      checkRows.value = []
      tableRef.value.clearCheckboxRow()
      fetchList()
    }

    const handleMark = (row, type) => {
      showMultipleMark.value = true
      markType.value = type
      checkRows.value = [row]
      multiple.value = false
    }

    const resolveStatusText = computed(() => status => registerStatusOptions.find(x => x.value === status)?.text)
    const statusMap = {
      SUCCESS: [9],
      FAIL: [0],
      PENDING: [2],
      ON: [1, 2, 3, 4],
      NORMAL: [8],
    }

    const hideDropdown = event => {
      if (!multipleRef.value.$el.contains(event.target)) {
        showBtns.value = false
      }
    }

    const handleShowBtns = () => {
      showBtns.value = !showBtns.value
      document.addEventListener('click', hideDropdown, true)
    }

    onUnmounted(() => {
      document.removeEventListener('click', hideDropdown)
    })

    return {
      active,
      pageList,
      pageLength,
      tableCols: tableColsFilter,
      searchConfig: searchConfigFilter,
      ...searchOthers,
      currItem,
      tableRef,
      showMultipleMark,
      checkRows,
      markType,
      multiple,
      showAudit,
      showAdd,
      showDetail,
      resolveStatusText,
      statusMap,
      multipleRef,

      handleExport,
      handleAdd,
      handleDetial,
      handleAudit,
      handleMultipleMark,
      handleMarkSuccess,
      handleMark,
      handleShowBtns,
      showBtns,
      can,
    }
  },
})
</script>

<template>
  <v-card elevation="0">
    <XwTabs
      :tabs="tabsConfig"
      :active="active"
      @change="handleTabChange"
    ></XwTabs>
    <div style="padding: 12px 20px">
      <!--搜索区域-->
      <XwSearch
        ref="searchRef"
        :form-config="searchConfig"
        @search="search"
      />
    </div>
    <!--按钮区域-->
    <div style="padding: 0 20px 20px 20px">
      <v-btn
        depressed
        outlined
        @click="handleExport"
      >
        导出
      </v-btn>
      <v-btn
        ref="multipleRef"
        v-auth="['超级管理员', '研发管理员', '总编室管理员']"
        outlined
        depressed
        class="btn-box ml-2"
        style="text-transform: none"
        @click="handleShowBtns"
      >
        <div class="btn-header">
          <div>
            批量标记<span
              class="iconfont ml-2 icon-a-xialakuang2x"
              :class="showBtns ? 'active' : ''"
            ></span>
          </div>
        </div>
        <div
          v-if="showBtns"
          class="btn-list"
        >
          <div
            class="btn-item"
            @click="handleMultipleMark('banner')"
          >
            批量标记Banner设计
          </div>
          <div
            class="btn-item"
            @click="handleMultipleMark('channel')"
          >
            批量标记频道设置
          </div>
        </div>
      </v-btn>
      <v-btn
        v-if="!can(['超级管理员', '研发管理员', '总编室管理员']) && can(['Banner设计组员'])"
        color="primary"
        outlined
        depressed
        class="ml-2"
        style="text-transform: none"
        @click="handleMultipleMark('banner')"
      >
        批量标记
      </v-btn>
      <v-btn
        v-if="!can(['超级管理员', '研发管理员', '总编室管理员']) && can(['FB频道设置组员', 'YT频道设置组员'])"
        color="primary"
        outlined
        depressed
        class="ml-2"
        @click="handleMultipleMark('channel')"
      >
        批量标记
      </v-btn>
    </div>
    <!-- table -->
    <div style="padding: 0 20px">
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <XwTable
        v-if="!loading"
        ref="tableRef"
        :columns="tableCols"
        :rows="pageList"
      >
        <template #status="{row}">
          <div
            v-if="resolveStatusText(row.status)"
            style="display: flex; align-items: center"
          >
            <XwStatus
              :status-map="statusMap"
              :status="row.status"
              :text="resolveStatusText(row.status)"
            ></XwStatus>
            <v-tooltip
              v-if="row.status === 0 && row.auditRemark"
              right
            >
              <template
                #activator="{ on, attrs }"
              >
                <span
                  v-bind="attrs"
                  class="ml-1 iconfont icon-a-tishishuoming2x2"
                  v-on="on"
                ></span>
              </template>
              <div>{{ row.auditRemark }}</div>
            </v-tooltip>
          </div>
          <span v-else>-</span>
        </template>
        <template #channelName="{row}">
          <div v-if="row.channels">
            <ChannelLink
              :channel="{
                platform: row.channel.platform,
                channelName: row.channel.channelName,
                link: row.channel.link
              }"
              :type="{
                subChannel: row.channel.subChannel,
                selfChannel: row.channel.selfChannel
              }"
              :self-channel="{
                platform: row.channel.selfPlatform,
                channelName: row.channel.selfChannelName,
                link: row.channel.selfChannelLink
              }"
              :channels="row.channels"
            />
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #registerChannel="{row}">
          <ChannelLink
            v-if="row.registerChannelId || row.oldChannelId"
            :channel="{
              platform: row.authorizePlatform,
              channelName: row.registerChannelName || row.oldChannelName,
              link: ''
            }"
          />
          <div v-else>
            -
          </div>
        </template>
        <template #opts="{row}">
          <div style="display: flex; gap: 10px">
            <a
              @click="handleDetial(row)"
            >
              详情
            </a>
            <a
              v-if="row.status === 2 && can(['超级管理员', '研发管理员', '总编室管理员', 'YT领用审核员', 'FB领用审核员'])"
              key="audit"
              @click="handleAudit(row)"
            >
              审核
            </a>
            <a
              v-if="row.status === 3 && can(['超级管理员', '研发管理员', '总编室管理员', 'YT频道管理员', 'FB主页管理员'])"
              key="register"
              @click="handleAdd(row)"
            >
              注册
            </a>
            <a
              v-if="(row.status === 4 || row.status === 9) && row.backendSettingFlag=== '否' && can(['超级管理员', '研发管理员', '总编室管理员', 'FB频道设置组员', 'YT频道设置组员'])"
              key="channel"
              @click="handleMark(row, 'channel')"
            >
              标记频道设置
            </a>
            <a
              v-if="(row.status === 4 || row.status === 9) && row.bannerDesignFlag === '否' && can(['超级管理员', '研发管理员', '总编室管理员', 'Banner设计组员'])"
              key="banner"
              @click="handleMark(row, 'banner')"
            >
              标记Banner设计
            </a>
          </div>
        </template>
      </XwTable>

      <div
        v-if="pageLength"
        style="padding: 20px 0"
      >
        <new-pagination
          :page-index="page"
          :page-size="pageSize"
          :total="pageLength"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!---批量标记-->
    <MultipleMark
      v-if="showMultipleMark"
      v-model="showMultipleMark"
      :check-rows="checkRows"
      :type="markType"
      :multiple="multiple"
      @success="handleMarkSuccess"
    />

    <!--注册频道/主页-->
    <Add
      v-if="showAdd"
      v-model="showAdd"
      :curr-item="currItem"
      :active="active"
      @success="handleRefresh"
    />
    <!---审核-->
    <Audit
      v-if="showAudit"
      v-model="showAudit"
      :curr-item="currItem"
      :active="active"
      @success="handleRefresh"
    ></Audit>
  </v-card>
</template>
<style lang="scss" scoped>
.register-channel {
  display: flex;
  align-items: center;
}
.channel-box{
  display: flex;
  flex-direction: column;
  .channel-item{
    color: #FFF!important;
  }
  ::v-deep .link-text{
    color: #fff;
  }
}
.btn-box{
  position: relative;
  .btn-list{
    position: absolute;
    background-color: #fff;
    left: -30px;
    top: 30px;
    z-index: 11;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-radius: 4px;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.1);
    .btn-item{
      padding: 10px;
      text-align: left;
      line-height: 20px;
      color: rgba(0,0,0,0.85);
    }
    .btn-item:hover{
      color: var(--v-primary-base);
      background-color: #f7f8f9;
    }

  }
  .btn-header{
    div{
      display: flex;
      align-items: center;
    }
    .iconfont{
      transition: 0.3s;
    }
    .active{
      transform: rotate(180deg);
    }
  }
}
</style>
