import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0"}},[_c('XwTabs',{attrs:{"tabs":_vm.tabsConfig,"active":_vm.active},on:{"change":_vm.handleTabChange}}),_c('div',{staticStyle:{"padding":"12px 20px"}},[_c('XwSearch',{ref:"searchRef",attrs:{"form-config":_vm.searchConfig},on:{"search":_vm.search}})],1),_c('div',{staticStyle:{"width":"100%","padding":"6px 20px 12px"}},[_c(VBtn,{staticClass:"mr-3",attrs:{"outlined":"","depressed":""},on:{"click":_vm.handleAdd}},[_vm._v(" 新增 ")])],1),_c('div',{staticStyle:{"padding":"0 20px"}},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('XwTable',{ref:"tableRef",attrs:{"columns":_vm.tableCols,"rows":_vm.pageList},scopedSlots:_vm._u([{key:"signPlatform",fn:function(ref){
var row = ref.row;
return [_c('Platform',{attrs:{"platform":row.signPlatform}})]}},{key:"playlist",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.registerPlatform !== 'YouTube' ? row.videoCustomTag || '-' : row.playlist || '-'))])]}},{key:"platform",fn:function(ref){
var row = ref.row;
return [_c('Platform',{attrs:{"platform":row.registerPlatform}})]}},{key:"signUrl",fn:function(ref){
var row = ref.row;
return [(row.signUrl)?_c('a',{staticClass:"text--link overflow",staticStyle:{"text-decoration":"none"},attrs:{"href":row.signUrl,"target":"_blank"}},[_vm._v(_vm._s(row.signUrl))]):_c('span')]}},{key:"opts",fn:function(ref){
var row = ref.row;
return [_c('a',{on:{"click":function($event){return _vm.handleRemove(row)}}},[_vm._v(" 删除 ")])]}}])}),(_vm.pageLength)?_c('div',[_c('new-pagination',{attrs:{"page-index":_vm.page,"page-size":_vm.pageSize,"total":_vm.pageLength},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1),(_vm.showDialog)?_c('Add',{attrs:{"curr-item":_vm.currItem,"active":_vm.active},on:{"success":_vm.handleRefresh},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }