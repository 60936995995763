import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { overseasPlatformOptions } from '@core/utils/options'
import { getCategory } from '@/views/audit/clueAudit/api'
import { getPage } from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(0)
  const searchRef = ref()
  const categoryOptions = ref([])

  const { message } = useMessage()

  // 查询初始化参数
  const queryDataOri = {
    title: null,
    platform: null,
    channelName: null,
    publishAtLeft: null,
    publishAtRight: null,
    categoryValue: null,
    group: null,
    operator: null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const searchConfig = computed(() => [
    {
      element: 'VTextField',
      props: 'title',
      placeholder: '视频标题',
      label: '视频标题',
    },
    {
      element: 'VAutocomplete',
      props: 'platform',
      placeholder: '发布平台',
      label: '发布平台',
      items: overseasPlatformOptions,
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
    {
      element: 'VCascader',
      props: 'categoryValue',
      placeholder: '垂类',
      label: '垂类',
      items: categoryOptions.value,
      multiple: false,
      itemText: 'name',
      itemValue: 'id',
    },
    {
      element: 'VTextField',
      props: 'channelName',
      placeholder: '发布频道',
      label: '发布频道',
    },
    {
      element: 'DateRangePickers',
      props: 'createAt',
      searchKey: ['publishAtLeft', 'publishAtRight'],
      initValue: [null, null],
      placeholder: '发布时间',
      label: '发布时间',
      clearable: true,
      range: true,
    },
  ])

  const fetchList = async () => {
    loading.value = true
    const {
      data: {
        data = [], status, total, message: messageText,
      },
    } = await getPage({
      page: page.value,
      pageSize: pageSize.value,
      ...queryData,
    })
    loading.value = false

    if (status === 200) {
      pageList.value = data
      pageLength.value = total
    } else {
      message.error(messageText)
    }
  }

  // 获取垂类
  const fetchCategory = async () => {
    const {
      data: { data = [], status, message: messageText },
    } = await getCategory()
    if (status === 200) {
      categoryOptions.value = data || []
    } else {
      message.error(messageText)
    }
  }

  const search = async params => {
    Object.assign(queryData, params)
    page.value = 1
    fetchList()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
  }

  const handleRefresh = () => {
    fetchList()
  }

  onMounted(() => {
    fetchList()
    fetchCategory()
  })

  return {
    searchConfig,
    queryData,
    queryDataOri,
    loading,
    pageList,
    page,
    pageSize,
    pageLength,
    searchRef,
    categoryOptions,

    search,
    fetchList,
    handleSizeChange,
    handleCurrentChange,
    handleRefresh,
  }
}
