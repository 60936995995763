const tableCols = [
  {
    text: '邮箱地址',
    sortable: false,
    value: 'emailAddress',
    active: 'youtube',
  },
  {
    text: '个人主页链接',
    sortable: false,
    value: 'pageLink',
    active: 'facebook',
  },
  {
    text: '使用人',
    sortable: false,
    value: 'useUserName',
  },
  {
    text: '更新时间',
    sortable: false,
    value: 'updatedAt',
  },
  {
    text: '操作',
    sortable: false,
    value: 'opts',
    slots: 'opts',
    width: 100,
    fixed: 'right',
  },
]

export default tableCols
