<script>
import {
  defineComponent, computed, ref, watch, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import {
  channelRatingOptions, clueChannelRatingOptions, rejectReasonOptions, channelTypeOptions, channelMultipleOptions,
} from '@core/utils/options'
import { required } from '@core/utils/validation'
import { saveAudit, save } from '@/views/audit/clueAudit/api'

export default defineComponent({
  name: 'Audit',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    activeItem: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const formRef = ref()
    const isLoading = ref(false)
    const audit = ref({
      ytType: null,
      fbType: null,
      ytCollectionType: 1,
      fbCollectionType: 1,
      ytRejectReason: null,
      fbRejectReason: null,
      auditStatus: null,
      auditResult: null,
      auditRemark: null,

      // rejectReason: null,
      channelLevel: null,
      auditDetailResult: null,
      level: null,
      channelType: props.currItem.channelTypeValue,
    })

    const formValid = ref(false)

    const { message } = useMessage()

    const handleClose = () => {
      emit('input', false)
    }

    const radioMap = [
      {
        text: '通过',
        value: 1,
      },
      {
        text: '拒绝',
        value: 0,
      },
    ]

    // fb或者yt有一个通过则显示通过
    const auditPass = computed(() => audit.value.ytType === 1 || audit.value.fbType === 1)

    // fb或者yt有一个拒绝则显示拒绝原因
    const showReason = computed(() => (audit.value.ytType !== 1 || audit.value.fbType !== 1) && audit.value.ytType !== null && audit.value.fbType !== null)

    // 计算审核状态枚举
    /* YT通过，FB通过   0
      YT通过，FB拒绝   1
      YT通过，FB没填   4
      YT拒绝，FB通过   2
      YT拒绝，FB拒绝   3
      YT拒绝，FB没填   5
      YT没填，FB通过   6
      YT没填，FB拒绝   7
      YT没填，FB没填   8
      */
    const calAuditResult = () => {
      if (audit.value.ytType === 1 && audit.value.fbType === 1) {
        return 0
      }
      if (audit.value.ytType === 1 && audit.value.fbType === 0) {
        return 1
      }
      if (audit.value.ytType === 1 && audit.value.fbType === null) {
        return 4
      }

      if (audit.value.ytType === 0 && audit.value.fbType === 1) {
        return 2
      }

      if (audit.value.ytType === 0 && audit.value.fbType === 0) {
        return 3
      }

      if (audit.value.ytType === 0 && audit.value.fbType === null) {
        return 5
      }

      if (audit.value.ytType === null && audit.value.fbType === 1) {
        return 6
      }

      if (audit.value.ytType === null && audit.value.fbType === 0) {
        return 7
      }

      if (audit.value.ytType === null && audit.value.fbType === null) {
        return 8
      }

      return null
    }

    const handleSubmit = async (submitType = 1) => {
      if (submitType === 1) {
        const valid = formRef.value.validate()
        if (!valid) return
      }

      // 处理channelType
      const params = {}
      if (auditPass.value) {
        params.channelType = audit.value.channelType
      }

      // 处理结果
      if ([0, 1, 2].includes(calAuditResult())) {
        params.auditResult = '通过'
      } else if ([3].includes(calAuditResult())) {
        params.auditResult = '拒绝'
      } else {
        params.auditResult = null
      }

      try {
        const { data: { data, status, message: messageText } } = submitType === 1 ? await saveAudit({
          id: props.currItem.id,
          auditStatus: submitType, // 1、已审核，2、审核中
          auditResult: params.auditResult,
          auditRemark: audit.value.auditRemark,

          // rejectReason: audit.value.rejectReason,
          channelLevel: audit.value.channelLevel,
          level: audit.value.level,
          auditDetailResult: calAuditResult(),
          ytCollectionType: audit.value.ytCollectionType,
          fbCollectionType: audit.value.fbCollectionType,
          ytRejectReason: audit.value.ytRejectReason,
          fbRejectReason: audit.value.fbRejectReason,
          activeItem: props.activeItem,
          ...params,
        }) : await save({
          id: props.currItem.id,
          auditStatus: submitType, // 1、已审核，2、审核中
          auditResult: params.auditResult,
          auditRemark: audit.value.auditRemark,

          // rejectReason: audit.value.rejectReason,
          channelLevel: audit.value.channelLevel,
          level: audit.value.level,
          auditDetailResult: calAuditResult(),
          ytCollectionType: audit.value.ytCollectionType,
          fbCollectionType: audit.value.fbCollectionType,
          ytRejectReason: audit.value.ytRejectReason,
          fbRejectReason: audit.value.fbRejectReason,
          activeItem: props.activeItem,
          ...params,
        })
        if (status === 200) {
          message.success('提交成功')
          emit('success', data)
          handleClose()
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    watch([() => audit.value.fbType, () => audit.value.ytType], () => {
      if (isLoading.value) return

      // audit.value.rejectReason = null
      if (audit.value.fbType === 0 && audit.value.ytType === 0) {
        audit.value.channelLevel = null
        audit.value.level = null
      }
    })

    watch(() => audit.value.fbType, val => {
      if (isLoading.value) return
      audit.value.fbRejectReason = null
      if (val) {
        audit.value.fbCollectionType = 1
      } else {
        audit.value.fbCollectionType = null
      }
    })

    watch(() => audit.value.ytType, val => {
      if (isLoading.value) return
      audit.value.ytRejectReason = null
      if (val) {
        audit.value.ytCollectionType = 1
      } else {
        audit.value.ytCollectionType = null
      }
    })

    const setType = () => {
      if ([0, 1, 4].includes(props.currItem.auditDetailResult)) {
        audit.value.ytType = 1
      }

      if ([2, 3, 5].includes(props.currItem.auditDetailResult)) {
        audit.value.ytType = 0
      }

      if ([0, 2, 6].includes(props.currItem.auditDetailResult)) {
        audit.value.fbType = 1
      }

      if ([1, 3, 7].includes(props.currItem.auditDetailResult)) {
        audit.value.fbType = 0
      }
    }

    onMounted(() => {
      if (props.currItem.id) {
        isLoading.value = true
        setType()
        audit.value = {
          ...audit.value,
          ...props.currItem,
          channelType: props.currItem.channelTypeValue,
          fbRejectReason: props.currItem.fbRejectReasonValue,
          ytRejectReason: props.currItem.ytRejectReasonValue,
          fbCollectionType: props.currItem.fbCollectionTypeValue,
          ytCollectionType: props.currItem.ytCollectionTypeValue,
        }
        setTimeout(() => {
          isLoading.value = false
        }, 500)
      }
    })

    return {
      showDialog,
      formRef,
      radioMap,
      audit,
      channelRatingOptions,
      clueChannelRatingOptions,
      required,
      auditPass,
      showReason,
      rejectReasonOptions,
      channelTypeOptions,
      channelMultipleOptions,
      formValid,
      isLoading,
      handleClose,
      handleSubmit,
      calAuditResult,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="400"
  >
    <v-card
      class="form-box"
    >
      <h3 class="form-title">
        线索审核<span v-if="currItem.channelName">({{ currItem.channelName }})</span>
        <v-btn
          icon
          small
          title="关闭"
          style="position: absolute; top: 16px; right: 20px;"
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </h3>
      <v-form
        ref="formRef"
        v-model="formValid"
        class="current-info"
      >
        <div class="current-item">
          <label>审核</label>
          <div style="display:flex; flex-direction: column">
            <div class="radio-item">
              <label style="padding-left: 12px">YT:</label>
              <v-radio-group
                v-model="audit.ytType"
                :rules="[required]"
                hide-details="true"
              >
                <v-radio
                  v-for="item in radioMap"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                  :ripple="false"
                ></v-radio>
              </v-radio-group>
            </div>
            <div
              class="radio-detail"
            >
              <div
                v-if="audit.ytType === 1"
                class="detail-item"
              >
                <label>类型</label>
                <v-radio-group
                  v-model="audit.ytCollectionType"
                  :rules="[required]"
                  hide-details="true"
                >
                  <v-radio
                    v-for="item in channelMultipleOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                    :ripple="false"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div
                v-if="audit.ytType === 0"
                class="detail-item"
              >
                <label>原因</label>
                <div>
                  <v-autocomplete
                    v-model.trim="audit.ytRejectReason"
                    :rules="[required]"
                    :items="rejectReasonOptions"
                    no-data-text="暂无数据"
                    hide-details
                    placeholder="请选择"
                    dense
                    outlined
                    clearable
                    style="width: 200px"
                  ></v-autocomplete>
                </div>
              </div>
            </div>
            <div class="radio-item">
              <label style="padding-left: 12px">FB:</label>
              <v-radio-group
                v-model="audit.fbType"
                :rules="[required]"
                hide-details="true"
              >
                <v-radio
                  v-for="item in radioMap"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                  :ripple="false"
                ></v-radio>
              </v-radio-group>
            </div>
            <div
              class="radio-detail"
            >
              <div
                v-if="audit.fbType === 1"
                class="detail-item"
              >
                <label>类型</label>
                <v-radio-group
                  v-model="audit.fbCollectionType"
                  :rules="[required]"
                  hide-details="true"
                >
                  <v-radio
                    v-for="item in channelMultipleOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                    :ripple="false"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div
                v-if="audit.fbType === 0"
                class="detail-item"
              >
                <label>原因</label>
                <div>
                  <v-autocomplete
                    v-model.trim="audit.fbRejectReason"
                    :rules="[required]"
                    :items="rejectReasonOptions"
                    no-data-text="暂无数据"
                    hide-details
                    placeholder="请选择"
                    dense
                    outlined
                    clearable
                    style="width: 200px"
                  ></v-autocomplete>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="audit.ytType !== null && audit.fbType !== null"
          class="current-item"
        >
          <label>审核结果</label>
          <div class="result-inner">
            <XwStatus
              :status="auditPass ? 1 : 3"
              :text="auditPass ? '通过': '拒绝'"
            ></XwStatus>
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  class="iconfont ml5 mt1 icon-a-tishishuoming2x1"
                  style="color: rgba(0, 0, 0, 0.40)"
                  v-on="on"
                ></span>
              </template>
              <span>任一平台通过，即为通过</span>
            </v-tooltip>
          </div>
        </div>
        <div
          v-if="auditPass && activeItem.key === 'channel'"
          class="current-item"
        >
          <label>频道评级</label>
          <div>
            <v-autocomplete
              v-model.trim="audit.channelLevel"
              :items="channelRatingOptions"
              :rules="[required]"
              item-text="text"
              item-value="value"
              no-data-text="暂无数据"
              hide-details="auto"
              placeholder="频道评级"
              dense
              outlined
              clearable
              style="width: 250px"
            ></v-autocomplete>
          </div>
        </div>
        <div
          v-if="auditPass && activeItem.key !== 'channel'"
          class="current-item"
        >
          <label>频道评级</label>
          <div>
            <v-autocomplete
              v-model.trim="audit.level"
              :items="clueChannelRatingOptions"
              :rules="[required]"
              item-text="text"
              item-value="value"
              no-data-text="暂无数据"
              hide-details="auto"
              placeholder="频道评级"
              dense
              outlined
              clearable
              style="width: 250px"
            ></v-autocomplete>
          </div>
        </div>
        <div
          v-if="auditPass && activeItem.key === 'channel'"
          class="current-item"
        >
          <label>频道类型</label>
          <div>
            <v-autocomplete
              v-model.trim="audit.channelType"
              :items="channelTypeOptions"
              item-text="text"
              item-value="value"
              no-data-text="暂无数据"
              hide-details="auto"
              placeholder="频道类型"
              dense
              outlined
              clearable
              style="width: 250px"
            ></v-autocomplete>
          </div>
        </div>
        <div class="current-item">
          <label>备注<span>(选填)</span></label>
          <div>
            <v-textarea
              v-model.trim="audit.auditRemark"
              placeholder="请把有效信息填写于此备注栏中，包括合集相关信息等。该备注信息会同步至商务，运营处。"
              style="width: 250px"
              hide-details="auto"
              dense
              outlined
              clearable
              maxlength="200"
              counter
              height="100"
              no-resize
            ></v-textarea>
          </div>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="handleClose"
        >
          取消
        </v-btn>
        <v-btn
          color="primary"
          outlined
          depressed
          @click="handleSubmit(2)"
        >
          保存
        </v-btn>
        <v-btn
          color="primary"
          depressed
          :disabled="!formValid"
          @click="handleSubmit(1)"
        >
          提交
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 16px 0 0 0;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  label{
    display: block;
    width: 94px;
    text-align: left;
    height: 34px;
    font-size: 14px;
    line-height: 34px;
    span{
      color: rgba(0, 0, 0, 0.25)
    }
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .current-info{
    padding: 10px 24px 0 24px;
    color: rgba(0, 0, 0, 0.85);
    .current-item{
      display: flex;
      align-items: left;
      margin-bottom: 10px;
      ::v-deep .v-input--selection-controls{
        margin-top: -2px;
      }
      ::v-deep .v-input--radio-group__input{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .v-radio{
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
      ::v-deep .v-label{
        font-size: 14px;
      }
      ::v-deep .v-input--selection-controls__input{
        width: 16px;
        height: 16px;
      }
      ::v-deep .v-textarea .v-text-field__details{
        position: relative;
        z-index: 2;
        margin-top: -20px;
      }
      .radio-item{
        display: flex;
        align-items: center;
        label{
          width: 50px;
          text-align: left;
        }
      }
      .result-inner{
        display: flex;
        align-items: center;
        font-size: 14px;
      }
      .radio-detail{

        .detail-item{
          background: #F6F7F8;
          padding: 5px 12px;
          border-radius: 4px;
          width: 260px;
          box-sizing: border-box;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          label{
            width: 46px;
            color: rgba(0,0,0,0.6);
          }
          ::v-deep .v-input__slot{
            min-height: 30px;
            .v-input__append-inner{
              margin-top: 3px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
