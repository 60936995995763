export default {
  PAGE: '/clue/page',
  AUDIT_COUNT: '/clue/tagShowCount',
  SAVE_AUDIT: '/clue/audit',
  COMPOSITION_PAGE: '/composition/page',
  COMPOSITION_AUDIT: '/composition/audit',
  EXPORT: '/clue/export',
  EXPORT_COMPOSITION: '/composition/export',
  CATEGORY: '/verticalCategory/tree',
  SAVE: '/clue/save',
  COMPOSITION_SAVE: '/composition/save',

  IMPORT: '/clue/import',
  IMPORT_COMPOSITION: '/composition/import',
  EXPORT_FAILURE: '/clue/exportFailure',
  EXPORT_FAILURE_COMPOSITION: '/composition/exportFailure',
}
