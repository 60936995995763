import {
  computed, reactive, ref, onMounted,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { getPage } from './api'

export default function useSearch() {
  const loading = ref(false)
  const pageList = ref([])
  const page = ref(1)
  const pageSize = ref(10)
  const pageLength = ref(0)
  const active = ref('youtube')
  const searchRef = ref()

  const { message } = useMessage()

  // 查询初始化参数
  const queryDataOri = {
    emailAddress: null,
    pageLink: null,
    useUser: null,
    useUser2: null,
  }

  // 查询参数增加响应
  const queryData = reactive({ ...queryDataOri })

  const searchConfig = computed(() => [
    {
      element: 'VTextField',
      props: 'emailAddress',
      placeholder: '邮箱地址',
      label: '邮箱地址',
      active: 'youtube',
    },
    {
      element: 'VTextField',
      props: 'pageLink',
      placeholder: '个人主页链接',
      label: '个人主页链接',
      active: 'facebook',
    },
    {
      element: 'VAutocomplete',
      props: 'useUser',
      placeholder: '使用人',
      label: '使用人',
      items: [],
      multiple: false,
      itemText: 'text',
      itemValue: 'value',
    },
  ])

  const fetchList = async () => {
    loading.value = true
    const {
      data: {
        data = [], status, total, message: messageText,
      },
    } = await getPage({
      page: page.value,
      pageSize: pageSize.value,
      ...queryData,
      type: active.value === 'youtube' ? 0 : 1,
    })
    loading.value = false

    if (status === 200) {
      pageList.value = data
      pageLength.value = total
    } else {
      message.error(messageText)
    }
  }

  const search = async params => {
    console.log(params, 'params')
    Object.assign(queryData, params)
    page.value = 1
    fetchList()
  }

  const handleRefresh = () => {
    fetchList()
  }

  const handleSizeChange = val => {
    page.value = 1
    pageSize.value = val
    fetchList()
  }
  const handleCurrentChange = val => {
    page.value = val
    fetchList()
  }

  const handleTabChange = tabItem => {
    active.value = tabItem.key
    page.value = 1
    Object.assign(queryData, queryDataOri)
    searchRef.value.reset()
  }

  onMounted(() => {
    fetchList()
  })

  return {
    searchConfig,
    queryData,
    queryDataOri,
    loading,
    pageList,
    page,
    pageSize,
    pageLength,
    active,
    searchRef,

    search,
    fetchList,
    handleRefresh,
    handleSizeChange,
    handleCurrentChange,
    handleTabChange,
  }
}
