import { homepageStatus, channelStates } from '@core/utils/options'

const tableCols = [
  {
    text: '',
    sortable: false,
    type: 'checkbox',
    width: 60,
  },
  {
    text: '频道ID',
    sortable: false,
    value: 'id',
    width: 200,
    active: 'youtube',
  },
  {
    text: '频道',
    sortable: false,
    value: 'channelName',
    width: 200,
    active: 'youtube',
  },
  {
    text: '主页ID',
    sortable: false,
    value: 'id',
    width: 200,
    active: 'facebook',
  },
  {
    text: '主页',
    sortable: false,
    value: 'channelName',
    width: 200,
    active: 'facebook',
  },
  {
    text: '垂类',
    sortable: false,
    value: 'categoryStr',
    width: 150,
  },
  {
    text: '与CMS关联',
    sortable: false,
    value: 'isLinked',
    width: 100,
    slots: 'isLinked',
    active: 'youtube',
  },
  {
    text: 'CMS名称',
    sortable: false,
    value: 'contentOwner',
    width: 100,
    active: 'youtube',
  },
  {
    text: '运营类型',
    sortable: false,
    value: 'pageAttribute',
    width: 100,
    active: 'youtube',
  },
  {
    text: '内容分类',
    sortable: false,
    value: 'contentType',
    width: 100,
    active: 'facebook',
  },
  {
    text: '主页属性',
    sortable: false,
    value: 'pageAttribute',
    width: 120,
    active: 'facebook',
  },

  {
    text: '运营团队',
    sortable: false,
    value: 'operationTeam',
    width: 150,
  },

  {
    text: '合集/单开',
    sortable: false,
    value: 'collectionType',
    width: 90,
    slots: 'channelType',
  },
  {
    text: '获利',
    sortable: false,
    width: 60,
    value: 'profit',
    slots: 'profit',
  },
  {
    text: '运营级别',
    sortable: false,
    value: 'incomeScale',
    width: 100,
  },
  {
    text: '搁置',
    sortable: false,
    value: 'isShelve',
    width: 70,
    slots: 'isShelve',
    active: 'facebook',
  },
  {
    text: '合作方名称',
    sortable: false,
    value: 'copyrightHolders',
    width: 150,
    slots: 'copyrightHolders',
  },
  {
    text: '订阅数',
    value: 'subscribe',
    width: 150,
    align: 'right',
    active: 'youtube',
  },
  {
    text: '昨日视频数',
    value: 'yesterdayVideoCount',
    width: 150,
    align: 'right',
    active: 'youtube',
  },
  {
    text: '今日视频数',
    value: 'videoCount',
    width: 150,
    align: 'right',
    active: 'youtube',
  },
  {
    text: '主页状态',
    sortable: false,
    value: 'pageStatus',
    width: 80,
    formatter: ({ cellValue }) => (homepageStatus.find(item => item.value === cellValue)?.text || '-'),
    active: 'facebook',
  },

  {
    text: '当前通道',
    sortable: false,
    value: 'currentChannel',
    minWidth: 150,
    slots: 'ways',
  },
  {
    text: '频道状态',
    sortable: false,
    value: 'pageStatus',
    width: 80,
    active: 'youtube',
    formatter: ({ cellValue }) => (channelStates.find(item => item.value === cellValue)?.text || '-'),
  },
  {
    text: '创建时间',
    sortable: false,
    value: 'createdAt',
    minWidth: 150,
  },
  {
    text: '操作',
    sortable: false,
    value: 'opts',
    slots: 'opts',
    width: 100,
    fixed: 'right',
  },
]

export default tableCols
