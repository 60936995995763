<script>
import {
  computed, defineComponent, ref,
} from '@vue/composition-api'
import { can } from '@core/utils/useAccess'
import Tag from '@/components/Tag.vue'
import {
  auditResultOptions, channelMultipleOptions,clueAuditStatusOptions
} from '@core/utils/options'
import { useMessage } from '@/hooks/useMessage'
import tableCols from './tableCols'
import useSearch from './useSearch'
import Audit from './components/Audit.vue'
import { getExport, clueImport } from './api'
import importXlsx from './components/importXlsx/importXlsx.vue'

export default defineComponent({
  components: {
    Audit,
    Tag,
    importXlsx,
  },
  setup() {
    const showDialog = ref(false)
    const currItem = ref({})
    const showXlsx = ref(false)
    const checkedObj = ref([])
    const searchParms = ref({})

    const fileData = ref({})
    const fileInputRef = ref()

    const {
      queryDataOri, fetchList, search, searchConfig, queryData, pageList, pageLength, auditCountParams, active, activeItem, ...searchOthers
    } = useSearch()

    const tabsConfig = computed(() => [
      {
        name: '频道',
        key: 'channel',
        value: -1,
        count: auditCountParams.value.channelCount,
      },
      {
        name: '视听作品',
        key: 'video',
        value: 0,
        count: auditCountParams.value.audioVisualCount,
      },
      {
        name: '图文作品',
        key: 'image',
        value: 1,
        count: auditCountParams.value.imageTextCount,
      },
      {
        name: '音乐作品',
        key: 'audio',
        value: 2,
        count: auditCountParams.value.musicCount,
      },
    ])

    // 分tab处理table和search配置
    const tableColsFilter = computed(() => tableCols.filter(x => !x.active || x.active.includes(active.value)))
    const searchConfigFilter = computed(() => searchConfig.value.filter(x => !x.active || x.active.includes(active.value)))

    const resolveCollectTypeText = computed(() => collectType => channelMultipleOptions.find(x => x.value === collectType)?.text)

    const handleAudit = row => {
      showDialog.value = true
      currItem.value = row
    }

    const auditAuth = ref(can(['超级管理员', '研发管理员', '总编室管理员', '审核专员']))
    const resolveAuditStatusText = computed(() => status => clueAuditStatusOptions.find(x => x.value === status)?.text)
    const resolveAuditResultText = computed(() => result => auditResultOptions.find(x => x.value === result)?.text)
    const { message, MessageBox } = useMessage()

    const handleExport = async () => {
      let idList = []
      if (checkedObj.value.length > 0) {
        idList = checkedObj.value.map(item => item.id)
        console.log('ids', idList)
      }

      message.warning('报表导出中！')
      const { data } = await getExport({ ...queryData, activeItem: activeItem.value, idList })

      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
      const url = window.URL.createObjectURL(blob) // 创建一个临时的url指向blob对象
      const a = document.createElement('a')
      a.href = url
      a.download = `线索${new Date().toLocaleString().replaceAll('/', '-')}审核表`
      a.click()
      window.URL.revokeObjectURL(url)
      message.success('导出完成！')
    }

    const selectChange = obj => {
      checkedObj.value = obj
    }
    const clearCheckboxRow = () => {
      checkedObj.value = []
    }
    const handerSearch = params => {
      checkedObj.value = []
      search(params)
      searchParms.value = params
    }

    const handerConfirmAgain = () => {
      console.log('pageLength',pageLength)
      if (pageLength.value <= 0) {
        message.error('您没有可导出的内容')

        return
      }

      if (pageLength.value >= 10000) {
        message.error('请缩小筛选范围，系统只允许导出10000条数据')

        return
      }
      let checkedObjLenth = checkedObj.value.length
      if (checkedObjLenth === 0) {
        checkedObjLenth = pageLength.value
      }

      MessageBox({
        title: '提示',
        message: `已选择${checkedObjLenth}条线索，是否确认导出？`,
        type: 'warning',
        showCancelButton: true,
      }).then(() => {
        handleExport()
      })
    }

    const handerImport = () => {
      fileInputRef.value.click()
    }
    const handleXlsx = async params => {
      const form = new FormData()
      form.append('file', params)
      const { data } = await clueImport({ form, activeItem: activeItem.value })
      showXlsx.value = true
      fileData.value = data
    }
    const handleChange = file => {
      if (!file?.target?.files.length) return
      const { target: { files: [targetFile = {}] } } = file
      handleXlsx(targetFile)
    }

    return {
      tabsConfig,
      tableCols: tableColsFilter,
      searchConfig: searchConfigFilter,
      currItem,
      checkedObj,
      searchParms,
      showXlsx,
      showDialog,
      activeItem,
      ...searchOthers,
      auditAuth,
      pageList,
      pageLength,
      active,
      resolveCollectTypeText,
      resolveAuditStatusText,
      resolveAuditResultText,
      handleAudit,
      handleExport,
      handerConfirmAgain,

      fileData,
      fileInputRef,

      handleXlsx,
      selectChange,
      clearCheckboxRow,
      handerSearch,
      handleChange,
      handerImport,
    }
  },
})
</script>

<template>
  <v-card elevation="0">
    <XwTabs
      :tabs="tabsConfig"
      :active="active"
      @change="handleTabChange"
    ></XwTabs>
    <div style="padding: 12px 20px">
      <!--搜索区域-->
      <XwSearch
        ref="searchRef"
        :form-config="searchConfig"
        @search="handerSearch"
      />
      <!-- @search="search" -->
    </div>
    <div style="padding: 0 20px 12px 20px;position: relative;">
      <v-btn
        outlined
        depressed
        class="btn-item mr-3"
        @click="handerConfirmAgain"
      >
        导出
      </v-btn>
      <!-- @click="handleXlsx" -->
      <v-btn
        outlined
        class="btn-item mr-3"
        v-if="auditAuth"
        @click="handerImport"
      >
        导入
      </v-btn>
      <input
        ref="fileInputRef"
        type="file"
        accept=".xls,.xlsx"
        :hidden="true"
        @change="handleChange"
        v-if="!showXlsx"
      />
    </div>
    <!-- table -->
    <div style="padding: 0 20px">
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <XwTable
        ref="tableRef"
        :columns="tableCols"
        :rows="pageList"
        @selectChange="selectChange"
        @clearCheckboxRow="clearCheckboxRow"
      >
        <template #channelName="{row}">
          <div style="padding-left: 15px; display: flex; align-items: center">
            <Tag
              v-if="row.reviewReason"
              tag-name="复"
              tag-key="waring"
              style="margin-left: -25px; margin-right: 5px"
            ></Tag>
            <div class="overflow">
              <a
                :href="row.channelLink"
                target="_blank"
                class="text--link"
                style="text-decoration: none"
              >{{ row.channelName || '-' }}</a>
            </div>
          </div>
        </template>
        <template #name="{row}">
          <div style="padding-left: 15px; display: flex; align-items: center">
            <Tag
              v-if="row.reviewReason"
              tag-name="复"
              tag-key="waring"
              style="margin-left: -25px; margin-right: 5px"
            ></Tag>
            <span>{{ row.name || '-' }}</span>
          </div>
        </template>
        <template #platform="{row}">
          <XwPlatform
            :platform="row.platform"
            :show-name="true"
          />
        </template>
        <template #link="{row}">
          <a
            :href="row.link"
            target="_blank"
            class="text--link overflow"
            style="text-decoration: none"
          >{{ row.link || '-' }}</a>
        </template>
        <template #auditStatus="{row}">
          <XwStatus
            v-if="resolveAuditStatusText(row.auditStatus)"
            :status="row.auditStatus"
            :text="resolveAuditStatusText(row.auditStatus)"
          ></XwStatus>
          <span v-else>-</span>
        </template>
        <template #reviewReason="{row}">
          <div>
            {{ row.reviewReason || '-' }} <span v-if="row.reviewRemark">({{ row.reviewRemark }})</span>
          </div>
        </template>
        <template #auditResult="{row}">
          <div
            v-if="row.auditResult && row.auditStatus !== 2"
            style="display: flex"
          >
            <XwStatus
              :status="row.auditResult === '通过' ? 1: 3"
              :text="row.auditResult"
            ></XwStatus>
            <v-tooltip right>
              <template
                #activator="{ on, attrs }"
              >
                <span
                  v-bind="attrs"
                  class="ml-1 iconfont icon-a-xiangqing2x2"
                  v-on="on"
                ></span>
              </template>
              <div
                v-if="row.auditResult"
                class="audit-result"
              >
                <div class="result-inner">
                  <div class="inner-item">
                    YT: <XwStatus
                      class="ml-2"
                      :status="row.auditDetailResult === 0 || row.auditDetailResult === 1 ? 1: 3"
                      :text="row.auditDetailResult === 0 || row.auditDetailResult === 1 ? '通过': '拒绝'"
                    ></XwStatus>
                    <span v-if="row.ytCollectionType || row.ytRejectReason">({{ row.auditDetailResult === 0 || row.auditDetailResult === 1 ? row.ytCollectionType : row.ytRejectReason }})</span>
                  </div>
                  <div class="inner-item">
                    FB: <XwStatus
                      class="ml-2"
                      :status="row.auditDetailResult === 0 || row.auditDetailResult === 2 ? 1: 3"
                      :text="row.auditDetailResult === 0 || row.auditDetailResult === 2 ? '通过': '拒绝'"
                    ></XwStatus><span v-if="row.fbCollectionType || row.fbRejectReason">
                      ({{ row.auditDetailResult === 0 || row.auditDetailResult === 2 ? row.fbCollectionType: row.fbRejectReason }})
                    </span>
                  </div>
                </div>
                <div class="result-remark">
                  <label>备注：</label><div>{{ row.auditRemark || '-' }}</div>
                </div>
              </div>
            </v-tooltip>
          </div>

          <span v-else>-</span>
        </template>
        <template #channelLevel="{row}">
          <div v-if="row.channelLevel && row.auditStatus !== 2">
            {{ row.channelLevel }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #level="{row}">
          <div v-if="row.level && row.auditStatus !== 2">
            {{ row.level }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #auditedUserName="{row}">
          <div v-if="row.auditedUserName && row.auditStatus !== 2">
            {{ row.auditedUserName }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #auditedAt="{row}">
          <div v-if="row.auditedAt && row.auditStatus !== 2">
            {{ row.auditedAt }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #opts="{row}">
          <a
            v-if="auditAuth"
            @click="handleAudit(row)"
          >
            审核
          </a>
        </template>
      </XwTable>

      <div
        v-if="pageLength"
      >
        <new-pagination
          :page-index="page"
          :page-size="pageSize"
          :total="pageLength"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--审核-->
    <Audit
      v-if="showDialog"
      v-model="showDialog"
      :active-item="activeItem"
      :curr-item="currItem"
      @success="handleRefresh"
    ></Audit>
    <!-- 导出 -->
    <importXlsx
      v-if="showXlsx"
      v-model="showXlsx"
      :curr-item="fileData"
      :active-item="activeItem"
      @success="handleRefresh"
    ></importXlsx>
  </v-card>
</template>
<style lang="scss" scoped>
.audit-result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .result-inner{
    padding: 10px 0;
    .inner-item{
      display: flex;
      padding: 2px 0;
    }
  }
  .result-remark{
    border-top: 1px solid rgba(255,255,255,0.1);
    padding: 12px 0;
    width: 100%;
    max-width: 600px;
    display: flex;
    label{
      min-width: 40px;
    }
  }
}
</style>
