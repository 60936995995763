<script>
import {
  computed, defineComponent, reactive, ref,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import Platform from '@/components/platform/Platform.vue'
import tableCols from './tableCols'
import useSearch from './useSearch'
import Add from './components/Add.vue'
import { removeWays } from './api'

export default defineComponent({
  components: {
    Platform,
    Add,
  },
  setup() {
    const tabsConfig = reactive([
      {
        name: '签约频道',
        key: 'channel',
      },
      {
        name: '视听作品',
        key: 'others',
      },
    ])
    const showDialog = ref(false)
    const currItem = ref({})

    const {
      queryDataOri, fetchList, searchConfig, active, queryData, ...searchOthers
    } = useSearch()

    // 分tab处理table和search配置
    const tableColsFilter = computed(() => tableCols.filter(x => !x.active || x.active === active.value))
    const searchConfigFilter = computed(() => searchConfig.value.filter(x => !x.active || x.active === active.value))

    const handleRefresh = () => {
      fetchList()
    }

    const handleAdd = () => {
      showDialog.value = true
      currItem.value = {}
    }

    const { message, MessageBox } = useMessage()

    const handleRemove = async item => {
      // 删除
      let messageStr = ''
      if (active.value === 'channel') {
        messageStr = `通道${item.signChannelName || ''}(${item.signPlatform || ''})至${item.registerChannelName || ''}(${item.registerPlatform || ''})将被删除，请确认`
      } else {
        messageStr = `通道${item.signChannelName || ''}至${item.registerChannelName || ''}(${item.registerPlatform || ''})将被删除，请确认`
      }
      MessageBox({
        message: messageStr,
        type: 'warning',
        showCancelButton: true,
      }).then(async () => {
        try {
          const {
            data: {
              status, message: messageText,
            },
          } = await removeWays({
            id: item.id,
            type: active.value === 'channel' ? 0 : 1,
          })
          if (status === 200) {
            message.success('删除成功')
            fetchList()
          } else {
            message.error(messageText)
          }
        } catch (err) {
          message.error(err.response.data.message)
        }
      })
    }

    return {
      active,
      tabsConfig,
      tableCols: tableColsFilter,
      searchConfig: searchConfigFilter,
      ...searchOthers,
      showDialog,
      currItem,

      handleRefresh,
      handleAdd,
      handleRemove,
    }
  },
})
</script>

<template>
  <v-card elevation="0">
    <XwTabs
      :tabs="tabsConfig"
      :active="active"
      @change="handleTabChange"
    ></XwTabs>
    <div style="padding: 12px 20px">
      <!--搜索区域-->
      <XwSearch
        ref="searchRef"
        :form-config="searchConfig"
        @search="search"
      />
    </div>
    <div style="width: 100%; padding: 6px 20px 12px">
      <!--按钮区域-->
      <v-btn
        outlined
        class="mr-3"
        depressed
        @click="handleAdd"
      >
        新增
      </v-btn>
    </div>
    <!-- table -->
    <div style="padding: 0 20px">
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <XwTable
        ref="tableRef"
        :columns="tableCols"
        :rows="pageList"
      >
        <template #signPlatform="{row}">
          <Platform
            :platform="row.signPlatform"
          />
        </template>
        <template #playlist="{row}">
          <div>{{ row.registerPlatform !== 'YouTube' ? row.videoCustomTag || '-' : row.playlist || '-' }}</div>
        </template>
        <template #platform="{row}">
          <Platform
            :platform="row.registerPlatform"
          />
        </template>
        <template #signUrl="{row}">
          <a
            v-if="row.signUrl"
            :href="row.signUrl"
            target="_blank"
            class="text--link overflow"
            style="text-decoration: none"
          >{{ row.signUrl }}</a>
          <span v-else></span>
        </template>
        <template #opts="{row}">
          <a
            @click="handleRemove(row)"
          >
            删除
          </a>
        </template>
      </XwTable>

      <div
        v-if="pageLength"
      >
        <new-pagination
          :page-index="page"
          :page-size="pageSize"
          :total="pageLength"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--新增-->
    <Add
      v-if="showDialog"
      v-model="showDialog"
      :curr-item="currItem"
      :active="active"
      @success="handleRefresh"
    />
  </v-card>
</template>
