<script>
import { defineComponent, computed } from '@vue/composition-api'
import { domPlatformListOptions } from '@core/utils/options'

export default defineComponent({
  name: 'Platform',
  props: {
    platform: {
      type: String,
      default: '',
    },
    showName: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const paltformEnum = {
      bilibili: ['B站', 'bilibili'],
      iqiyi: ['爱奇艺', 'iqiyi'],
      haokan: ['好看', 'haokan'],
      douyin: ['抖音', 'douyin'],
      xigua: ['西瓜', 'xigua'],
      kuaishou: ['快手', 'kuaishou'],
      xiaohongshu: ['小红书', 'xiaohongshu'],
      youtube: ['Youtube', 'youtube'],
      facebook: ['Facebook', 'facebook'],
      // jingre: ['今日头条', 'jingre'],
      // youku: ['优酷', 'youku'],
      // TikTok: ['TikTok', 'TikTok'],
      // weibo: ['微博', 'weibo'],
    }

    const imgName = computed(() => props.platform && Object.keys(paltformEnum).find(key => paltformEnum[key].includes(props.platform.toLowerCase())))
    // eslint-disable-next-line import/no-dynamic-require
    const imgSrc = computed(() => (imgName ? require(`@/assets/images/logos/${imgName.value}.png`) : ''))
    const platformName = computed(() => [...domPlatformListOptions].find(x => x.value === imgName.value)?.label || '')

    return {
      platformName,
      imgSrc,
      imgName,
    }
  },
})
</script>

<template>
  <div
    v-if="imgName"
    class="platform"
  >
    <img
      :src="imgSrc"
      class="mr5"
    >
    <span v-if="showName && platformName">{{ platformName }}</span>
  </div>
  <div v-else-if="showName">
    <!-- - -->
     {{ platform }}
  </div>
</template>
<style lang="scss" scoped>
.platform {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
