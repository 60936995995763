<script>
import {
  defineComponent, computed, ref,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import dayjs from 'dayjs'
import { getTimeLine } from '../../api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const showDialog = computed(() => props.value)
    const showMore = ref(false)
    const list = ref([])

    const { message } = useMessage()

    const handleClose = () => {
      emit('input', false)
    }

    const generateList = (data = []) => {
      const resultObj = {}
      data.forEach(row => {
        const item = {}
        item.createdAt = row.createdAt
        item.class = 'normal'
        item.year = dayjs(row.createdAt).format('YYYY')
        item.date = dayjs(row.createdAt).format('MM/DD')
        const dateTime = dayjs(row.createdAt).format('YYYY-MM-DD')

        if (row.contractStatus === '引入' || row.contractStatus === '创建') {
          item.content = row.contractStatus
        } else if (row.contractStatus === '解约') {
          item.content = '解约'
          item.class = 'danger'
        } else if (row.operation === '解绑') { // status 0绑定1解绑
          item.class = 'waring'
          item.content = `解绑 ${props.type === 'signChannel' ? row.registerPlatform || '' : row.signPlatform || ''}: ${props.type === 'signChannel' ? row.registerChannelName || '' : row.signChannelName || ''}`
        } else {
          item.content = `绑定 ${props.type === 'signChannel' ? row.registerPlatform || '' : row.signPlatform || ''}: ${props.type === 'signChannel' ? row.registerChannelName || '' : row.signChannelName || ''}`
        }

        // 相同日期合并
        if (!resultObj[dateTime]) {
          resultObj[dateTime] = []
        }
        resultObj[dateTime].push(item)
      })

      return Object.values(resultObj).map(item => ({
        year: item[0].year,
        date: item[0].date,
        records: item,
      }))
    }

    const fetchTimeLine = async () => {
      try {
        const {
          data: {
            data = [], status, message: messageText,
          },
        } = await getTimeLine({
          id: props.currItem.id,
          type: props.type,
        })
        if (status === 200) {
          list.value = generateList(data)
        } else {
          message.error(messageText)
        }
      } catch (err) {
        message.error(err.response.data.message)
      }
    }

    fetchTimeLine()

    return {
      showDialog,
      showMore,
      list,
      handleClose,
      dayjs,
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="500"
  >
    <v-card class="form-box">
      <h3 class="form-title">
        时间线<span v-if="currItem.channelName">({{ currItem.channelName }})</span>
        <v-btn
          icon
          small
          title="关闭"
          style="position: absolute; top: 16px; right: 20px;"
          @click="handleClose"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </h3>
      <div class="current-info">
        <div class="current-item">
          <label>当前通道：</label>
          <div
            v-if="currItem.currentChannels && currItem.currentChannels.length"
            class="ways-list"
          >
            <a
              v-if="currItem.currentChannels.length > 3"
              class="showmore-btn"
              @click="showMore = !showMore"
            >{{ showMore ? '收起' : '展开' }}</a>
            <span
              v-for="(item, index) in currItem.currentChannels"
              v-show="showMore || index < 3"
              :key="index"
            >{{ item }}</span>
          </div>
          <div
            v-else
            class="ways-list"
          >
            -
          </div>
        </div>
      </div>
      <div
        v-if="list.length"
        class="timeline-box"
      >
        <v-timeline
          dense
          align-top
          fill-dot
        >
          <v-timeline-item
            v-for="(item, index) in list"
            :key="index"
            fill-dot
            small
            color="rgba(212, 214, 219, 1)"
          >
            <div class="timeline-lt">
              <strong>{{ item.year }}</strong>
              <span>{{ item.date }}</span>
            </div>
            <div
              class="timeline-inner"
            >
              <div
                v-for="(records, idxChild) in item.records"
                :key="idxChild"
                class="inner-item"
                :class="records.class"
              >
                {{ records.content }}
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          outlined
          style="min-width: 65px"
          @click="handleClose"
        >
          关闭
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.form-box{
  padding: 20px 0 0 0;
  font-size: 14px;
  .form-title{
    margin: 0 24px;
    padding-bottom: 16px;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #F2F3F5;
    span{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.80);
      font-weight: normal;
    }
  }
  ::v-deep .v-card__actions .v-btn{
    min-width: 65px;
  }
  .current-info{
    padding: 10px 20px 20px 40px;
    .current-item{
      display: flex;
      align-items: left;
    }
    .ways-list{
      display: flex;
      flex-direction: column;
      position: relative;
      min-width: 100px;
      .showmore-btn{
        position: absolute;
        right: -40px;
        top: 0;
      }
    }
  }
  ::v-deep .v-timeline{
    padding-top: 0;
    padding-bottom: 0;
    .v-timeline-item:last-child{
      height: 0;
      .timeline-inner{
        padding-bottom: 24px;
      }
    }
  }
  .timeline-box{
    max-height: 300px;
    min-height: 200px;
    overflow-y: auto;
    margin: 0 24px 24px 24px;
    padding: 32px 0 24px 24px;
    background-color:#F6F7F8;
    .timeline-lt{
      position: absolute;
      left: -100px;
      top: -4px;
      display: flex;
      flex-direction: column;
    }
    .timeline-inner{
      margin-left: -24px;
      margin-top: -4px;
      .inner-item{
        height: 20px;
        margin-bottom: 10px;
        line-height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .inner-item::before{
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        margin-right: 4px;
        background-color: #8e8e8e;
      }
      .inner-item.waring::before{
        background: #FFA80F;
      }
      .inner-item.danger::before{
        background: #F24545;
      }
    }
    ::v-deep .v-timeline-item__dot--small{
      width: 12px;
      height: 12px;
    }
    ::v-deep .v-timeline-item__dot--small .v-timeline-item__inner-dot::before{
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #fff;
      content: '';
      display: block;
      margin: 3px;
    }
  }
}
</style>
